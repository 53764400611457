<script setup>
import { useNuxtApp } from '#app'
import Toast from './toast.vue'

const { $toast } = useNuxtApp()
const toasts = $toast.toasts

</script>
<template>
    <div class="flex flex-col items-center justify-center gap-2 w-full  ">
        <Toast v-for="toast in toasts" :key="toast.id" v-bind="toast" @close="removeToast(toast.id)" />
    </div>
</template>


  