import { reactive } from 'vue'
import { defineNuxtPlugin } from 'nuxt/app';

type Toast ={
  id: number;
  title: string;
  color: string;
  duration: number;
  position: string;
}
export default defineNuxtPlugin((nuxtApp) => {
  const toasts = reactive<Toast[]>([]);

  const addToast = ({ title="Something is happening", color = 'green', duration = 3000,position="bottom-10" }) => {
    const id = Date.now()

    
    toasts.push({ id, title, color, duration,position} as Toast)
    setTimeout(() => removeToast(id), duration)
  }

  const removeToast = (id:number) => {
    const index = toasts.findIndex((toast) => toast.id === id)
    if (index !== -1) {
      toasts.splice(index, 1)
    }
  }

  nuxtApp.provide('toast', { addToast, toasts })
})
