import { defineStore } from "pinia";
import axios from 'axios';
import { useRuntimeConfig } from "nuxt/app";

export const useValidationStore = defineStore("validation", {
  state: () => ({
    validations: {} as { [key: string]: any | null },
  }),
  getters: {
    validationsGetter: (state) => state.validations,
  },
  actions: {
    setValidationsAction(validations: any) {
      const editedValidations = Object.keys(validations).reduce<Record<string, any>>((acc, key) => {
        if (key.includes('.')) {
          const newKey = key.split('.').pop();
          if (newKey) {
            acc[newKey] = validations[key];
          }
        } else {
          acc[key] = validations[key];
        }
        return acc;
      }, {});
      this.validations = { ...this.validations, ...editedValidations };
    },
    resetValidationByNameAction(name: any) {
      this.validations[name] = null
    },
    resetValidationAction() {
      this.validations = {}
    }
  }
});