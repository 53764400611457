<script setup>
import {ref, computed} from 'vue'
import { useSettingsStore } from './stores/settings.store';
import { useLayoutStore } from './stores/layout.store';
import { useUserStore } from './stores/user.store';
import { useRolesStore } from './stores/account/roles.store'

        const head = useLocaleHead({
            addDirAttribute: true,
            identifierAttribute: 'id',
            addSeoAttributes: true
        })
        const layoutStore = useLayoutStore();
        const settingsStore = useSettingsStore();
        const userStore = useUserStore();
        const rolesStore = useRolesStore()

        await settingsStore.fetchSettings();
        await layoutStore.fetchCurrencies()
        await layoutStore.fetchLanguages();
        await userStore.getUserData();
        await rolesStore.fetchRoles()
</script>

<template>
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
        <Body class="bg-neutral-100 flex h-full flex-col">
            <NuxtLoadingIndicator />
                <NuxtPage />
                <w-notifications/>
        </Body>
    </Html>
</template>
