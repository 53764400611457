import { defineStore } from "pinia";
import { useSsrFetch } from "../composables/useFetch";
import type { Settings } from "../types/settings.type";

export const useSettingsStore = defineStore("settings", {
	state: () => ({
		settings: {} as Settings,
		default_urls: {},
	}),
	getters: {
		settingsGetter: (state) => () => state.settings,
		defaultUrls: (state) => () => {
			return {
				location: state.settings.seo_default_slug_location,
				category: state.settings.seo_default_slug_category,
				purpose: state.settings.seo_default_slug_purpose,
			};
		},
	},
	actions: {
		async fetchSettings(): Promise<Settings> {
			const settings = await useSsrFetch("data/settings") as Settings;
			this.settings = settings;
			return settings;
		},
	},
});
