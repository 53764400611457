import axios from 'axios';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { useValidationStore } from '~/stores/account/validations.store';

export default class CallApi {
  add_key_secret: boolean;
  successToastr: boolean;
  toastr: boolean;
  errorToastr: boolean;
  api: any;
  config = useRuntimeConfig();
  validationStore = useValidationStore();
  nuxtApp = useNuxtApp();
  router = useRouter();
  localePath = useLocalePath();

  constructor({ with_token = true, add_key_secret = false, successToastr = false, toastr = false, errorToastr = false } = {}) {
    const locale = useNuxtApp().$i18n.locale.value || 'en';
    this.add_key_secret = add_key_secret;
    this.successToastr = successToastr;
    this.toastr = toastr;
    this.errorToastr = errorToastr;
    this.api = axios.create({
      baseURL: this.config.public.vcrmAccountApiUrl as string,
      headers: {
        'application-language': locale || 'en',
        'Application-Version': '1.0.0',
        'application-platform': 'web',
      },
    });
    if (with_token) {
      this.add_authorization_header();
    }
  }
  check_key_secret(data: any) {
    if (this.add_key_secret) {
      data['app_key'] = this.config.public.appKey;
      data['app_secret'] = this.config.public.appSecret;
    }
    return data;
  }
  add_authorization_header() {
    const token = this.getVcrmAccountToken()
    this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  getVcrmAccountToken() {
    if (!document) {
      return false;
    }
    const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
    if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
      return vue_cookie.split("=")[1];
    }
  }
  check_status(res: any) {
    if (res == undefined) {
    } else if (res.status === 200) {
      if (this.toastr || this.successToastr) {
        (this.nuxtApp as any).$toast.addToast({
          title: res?.data?.message,
          timeout: 2000,
          position: 'top-10',
        })
      }
      this.validationStore.resetValidationAction();
      return res;
    } else if (res.status == 422) {
      this.validationStore.setValidationsAction(res?.data)
    } else if (res.status == 401) {
      if (document) {
        document.cookie = `vcrm-account-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${document.domain};`;
      }
    }
    return res;
  }
  async get(path: string) {
    try {
      const res = await this.api.get(path);
      return this.check_status(res);
    } catch (err: any) {
      const rejectResponse = this.check_status(err.response);
      return rejectResponse;
    }
  }
  async post(path: string, data: any) {
    try {
      const res = await this.api.post(path, this.check_key_secret(data));
      return this.check_status(res);
    } catch (err: any) {
      const rejectResponse = this.check_status(err.response);

      return rejectResponse;
    }
  }
  async put(path: string, data: any) {
    try {
      const res = await this.api.put(path, this.check_key_secret(data));
      return this.check_status(res);
    } catch (err: any) {
      const rejectResponse = this.check_status(err.response);
      return rejectResponse;
    }
  }
  async delete(path: string) {
    try {
      const res = await this.api.delete(path);
      return this.check_status(res);
    } catch (err: any) {
      return this.check_status(err.response);
    }
  }
}
