<script setup>
const props = defineProps({
title: String,
color: {
    type: String,
    default: 'green', 
},
duration: {
    type: Number,
    default: 3000, // Duration in milliseconds
},
position:{
    type: String,
    default: 'bottom-10', 
}
})


const visible = ref(true)
const progress = ref(100)

const closeToast = () => {
visible.value = false
}

const startProgressBar = () => {
const interval = 100 // Adjust this interval for smoother or faster updates
const decrement = (interval / props.duration) * 100

const progressInterval = setInterval(() => {
    progress.value -= decrement
    if (progress.value <= 0) {
    closeToast()
    clearInterval(progressInterval)
    }
}, interval)
}

onMounted(() => {
startProgressBar()
})
</script>

<template>
    <div
        v-if="visible"
       
        :class="`fixed w-[23rem] z-[9999]  ${position} mx-auto flex items-center p-4 rounded shadow-lg cursor-pointer text-black bg-white`"
    >
        <p>{{ title }}</p>
        <div
        :class="`absolute bottom-0 left-0 h-1 bg-${color}-500 opacity-50 font-semibold transition-width duration-100`"
        :style="{ width: `${progress}%` }"
        ></div>
        <span class="i-heroicons-x-mark-20-solid flex-shrink-0 h-5 w-5 absolute right-2 text-gray-400  " aria-hidden="true"  @click="closeToast"></span>
    </div>
</template>


  

  