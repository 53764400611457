import { defineStore } from "pinia";
import helper from "../resources/js/libraries/helper";
import axios from "axios";
import { useRouter, useRuntimeConfig } from "nuxt/app";
import CallApi from "../api-services";


export const useUserStore = defineStore({
	id: "user",
	state: () => ({
		user: null as { [key: string]: any } | null,
		mobiles: null as { [key: string]: any }[] | null,
	}),
	getters: {
		userGetter: (state) => () => state.user,
		mobilesGetter: (state) => () => state.mobiles,
	},
	actions: {
		async logout() {
			const router = useRouter();
			const localePath = useLocalePath();
			await new CallApi({}).get('/profile/logout').then(() => {
				if (document) {
					document.cookie = `vcrm-account-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${document.domain};`;
				}
				this.user = null;
				router.push(localePath({ name: "home" }));
			});
		},
		async getUserData() {
			if (!(new CallApi({}).getVcrmAccountToken())) {
				return;
			}
			await new CallApi({}).get('/profile').then((res: any) => {
				this.user = res?.data?.user;
				return res;
			});
		},

		async changeEmail(form: { email: string, old_email: string }) {
			return await new CallApi({ successToastr: true }).put('/profile/email', form);
		},

		async changePassword(form: { current_password: string, password: string, password_confirmation: string }) {
			return await new CallApi({ successToastr: true }).put('/profile/password', form);
		},

		async changePicture(picture: string) {
			return await new CallApi({ successToastr: true }).put('/profile/picture', { picture });
		},

		async changeName(form: { first_name: string, last_name: string }) {
			return await new CallApi({ successToastr: true }).put('/profile/full-name', form);
		},

		async getMobiles() {
			return await new CallApi().get('/mobiles').then((res: any) => {
				this.mobiles = res?.data;
			});
		},

		async addMobile(form: { mobile: string, country_id: number, show_mobile_in_listing: boolean }) {
			return await new CallApi({ successToastr: true }).post('/mobiles', form);
		},

		async updateMobile(form: { show_mobile_in_listing: boolean }) {
			return await new CallApi({ successToastr: true }).put('/mobiles/update-mobile', form);
		},

		async deleteMobile() {
			return await new CallApi({ successToastr: true }).delete('/mobiles');
		},
	},
});
