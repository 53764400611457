import type { RouterOptions } from '@nuxt/schema';

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterOptions>{
	routes: (_routes) => [
		{ name: 'home___en', path: '/', component: () => import('~/layouts/default.vue') },
		// Add Arabic routes.
		{ name: 'home___ar', path: '/ar/', component: () => import('~/layouts/default.vue') },
		{ name: 'guides.index___ar', path: '/ar/guides', component: () => import('~/layouts/default.vue') },
		{ name: 'guides.show___ar', path: '/ar/guides/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'projects.index___ar', path: '/ar/projects/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'contact_us___ar', path: '/ar/contact-us', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.show___ar', path: '/ar/listings/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.add___ar', path: '/ar/listings/add', component: () => import('~/layouts/default.vue') },
		{ name: 'listing.preview___ar', path: '/ar/listings/preview', component: () => import('~/layouts/default.vue') },
		{ name: 'listing.last_seen___ar', path: '/ar/listings/last-seen', component: () => import('~/layouts/default.vue') },
		{ name: 'shared.favorite___ar', path: '/ar/shared/{hash}/favorites', component: () => import('~/layouts/default.vue') },
		{ name: 'teammate.index___ar', path: '/ar/our-team', component: () => import('~/layouts/default.vue') },
		{ name: 'developers.index___ar', path: '/ar/developers', component: () => import('~/layouts/default.vue') },
		{ name: 'developers.show___ar', path: '/ar/developers/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'more___ar', path: '/ar/more', component: () => import('~/layouts/default.vue') },
		{ name: 'search___ar', path: '/ar/search', component: () => import('~/layouts/default.vue') },
		{ name: 'account.login___ar', path: '/ar/account/login', component: () => import('~/layouts/default.vue') },
		{ name: 'account.register___ar', path: '/ar/account/register', component: () => import('~/layouts/default.vue') },
		{ name: 'account.verify___ar', path: '/ar/account/verify', component: () => import('~/layouts/default.vue') },
		{ name: 'account.forget.password___ar', path: '/ar/account/forget-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.notifications___ar', path: '/ar/account/notifications', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers___ar', path: '/ar/account/customers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.show___ar', path: '/ar/account/customers/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.create___ar', path: '/ar/account/customers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.edit___ar', path: '/ar/account/customers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.index___ar', path: '/ar/account/offers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.create___ar', path: '/ar/account/offers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.edit___ar', path: '/ar/account/offers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.index___ar', path: '/ar/account/profile', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_email___ar', path: '/ar/account/profile/change-email', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_password___ar', path: '/ar/account/profile/change-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_name___ar', path: '/ar/account/profile/change-name', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.mobile_phones___ar', path: '/ar/account/profile/mobile-phones', component: () => import('~/layouts/default.vue') },
		{ name: 'sitemap.index___ar', path: '/ar/sitemaps', component: () => import('~/layouts/default.vue') },
		{ name: 'one.index___ar', path: '/ar/one', component: () => import('~/pages/desktop/sitemaps/index.vue') },
		{ name: 'qr_code.index___ar', path: '/ar/vqrtur', component: () => import('~/pages/desktop/qr-code/index.vue') },
		{ name: 'slug___ar', path: '/ar/:slug*', component: () => import('~/layouts/default.vue') },

		// Add Persian routes
		{ name: 'home___fa', path: '/fa/', component: () => import('~/layouts/default.vue') },
		{ name: 'guides.index___fa', path: '/fa/guides', component: () => import('~/layouts/default.vue') },
		{ name: 'guides.show___fa', path: '/fa/guides/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'projects.index___fa', path: '/fa/projects/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'contact_us___fa', path: '/fa/contact-us', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.show___fa', path: '/fa/listings/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.add___fa', path: '/fa/listings/add', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.preview___fa', path: '/fa/listings/preview', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.last_seen___fa', path: '/fa/listings/last-seen', component: () => import('~/layouts/default.vue') },
		{ name: 'shared.favorite___fa', path: '/fa/shared/{hash}/favorites', component: () => import('~/layouts/default.vue') },
		{ name: 'teammate.index___fa', path: '/fa/our-team', component: () => import('~/layouts/default.vue') },
		{ name: 'developers.index___fa', path: '/fa/developers', component: () => import('~/layouts/default.vue') },
		{ name: 'developers.show___fa', path: '/fa/developers/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'more___fa', path: '/fa/more', component: () => import('~/layouts/default.vue') },
		{ name: 'search___fa', path: '/fa/search', component: () => import('~/layouts/default.vue') },
		{ name: 'account.login___fa', path: '/fa/account/login', component: () => import('~/layouts/default.vue') },
		{ name: 'account.register___fa', path: '/fa/account/register', component: () => import('~/layouts/default.vue') },
		{ name: 'account.verify___fa', path: '/fa/account/verify', component: () => import('~/layouts/default.vue') },
		{ name: 'account.forget.password___fa', path: '/fa/account/forget-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.notifications___fa', path: '/fa/account/notifications', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers___fa', path: '/fa/account/customers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.show___fa', path: '/fa/account/customers/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.create___fa', path: '/fa/account/customers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.edit___fa', path: '/fa/account/customers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.index___fa', path: '/fa/account/offers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.create___fa', path: '/fa/account/offers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.edit___fa', path: '/fa/account/offers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.index___fa', path: '/fa/account/profile', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_email___fa', path: '/fa/account/profile/change-email', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_password___fa', path: '/fa/account/profile/change-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_name___fa', path: '/fa/account/profile/change-name', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.mobile_phones___fa', path: '/fa/account/profile/mobile-phones', component: () => import('~/layouts/default.vue') },
		{ name: 'sitemap.index___fa', path: '/fa/sitemaps', component: () => import('~/layouts/default.vue') },
		{ name: 'one.index___fa', path: '/fa/one', component: () => import('~/pages/desktop/sitemaps/index.vue') },
		{ name: 'qr_code.index___fa', path: '/fa/vqrtur', component: () => import('~/pages/desktop/qr-code/index.vue') },
		{ name: 'slug___fa', path: '/fa/:slug*', component: () => import('~/layouts/default.vue') },

		// Add Turkish routes.
		{ name: 'home___tr', path: '/tr/', component: () => import('~/layouts/default.vue') },
		{ name: 'guides.index___tr', path: '/tr/guides', component: () => import('~/layouts/default.vue') },
		{ name: 'guides.show___tr', path: '/tr/guides/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'projects.index___tr', path: '/tr/projeler/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'contact_us___tr', path: '/tr/bize-ulasin', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.show___tr', path: '/tr/ilan/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.add___tr', path: '/tr/ilan/add', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.preview___tr', path: '/tr/ilan/preview', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.last_seen___tr', path: '/tr/ilan/last-seen', component: () => import('~/layouts/default.vue') },
		{ name: 'teammate.index___tr', path: '/tr/ekibimiz', component: () => import('~/pages/desktop/teammates/index.vue') },
		{ name: 'teammate.show___tr', path: '/tr/ekibimiz/:id', component: () => import('~/pages/desktop/teammates/show.vue') },
		{ name: 'developers.index___tr', path: '/tr/gelistiriciler', component: () => import('~/layouts/default.vue') },
		{ name: 'developers.show___tr', path: '/tr/gelistiriciler/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'more___tr', path: '/tr/more', component: () => import('~/layouts/default.vue') },
		{ name: 'search___tr', path: '/tr/search', component: () => import('~/layouts/default.vue') },
		{ name: 'account.login___tr', path: '/tr/account/login', component: () => import('~/layouts/default.vue') },
		{ name: 'account.register___tr', path: '/tr/account/register', component: () => import('~/layouts/default.vue') },
		{ name: 'account.verify___tr', path: '/tr/account/verify', component: () => import('~/layouts/default.vue') },
		{ name: 'account.forget.password___tr', path: '/tr/account/forget-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.notifications___tr', path: '/tr/account/notifications', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers___tr', path: '/tr/account/customers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.show___tr', path: '/tr/account/customers/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.create___tr', path: '/tr/account/customers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.edit___tr', path: '/tr/account/customers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.index___tr', path: '/tr/account/offers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.create___tr', path: '/tr/account/offers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.edit___tr', path: '/tr/account/offers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.index___tr', path: '/tr/account/profile', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_email___tr', path: '/tr/account/profile/change-email', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_password___tr', path: '/tr/account/profile/change-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_name___tr', path: '/tr/account/profile/change-name', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.mobile_phones___tr', path: '/tr/account/profile/mobile-phones', component: () => import('~/layouts/default.vue') },
		{ name: 'sitemap.index___tr', path: '/tr/site-haritasi', component: () => import('~/layouts/default.vue') },
		{ name: 'qr_code.index___tr', path: '/tr/vqrtur', component: () => import('~/pages/desktop/qr-code/index.vue') },
		{ name: 'slug___tr', path: '/tr/:slug*', component: () => import('~/layouts/default.vue') },

		// Add Russian routes.
		{ name: 'home___ru', path: '/ru/', component: () => import('~/layouts/default.vue') },
		{ name: 'guides.index___ru', path: '/ru/guides', component: () => import('~/layouts/default.vue') },
		{ name: 'guides.show___ru', path: '/ru/guides/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'projects.index___ru', path: '/ru/proekty/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'contact_us___ru', path: '/ru/svyazatsya-s-nami', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.show___ru', path: '/ru/spiski/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.add___ru', path: '/ru/spiski/add', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.preview___ru', path: '/ru/spiski/preview', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.last_seen___ru', path: '/ru/spiski/last-seen', component: () => import('~/layouts/default.vue') },
		{ name: 'teammate.index___ru', path: '/ru/nasha-komanda', component: () => import('~/layouts/default.vue') },
		{ name: 'developers.index___ru', path: '/ru/razrabotchiki', component: () => import('~/layouts/default.vue') },
		{ name: 'developers.show___ru', path: '/ru/razrabotchiki/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'more___ru', path: '/ru/more', component: () => import('~/layouts/default.vue') },
		{ name: 'search___ru', path: '/ru/search', component: () => import('~/layouts/default.vue') },
		{ name: 'account.login___ru', path: '/ru/account/login', component: () => import('~/layouts/default.vue') },
		{ name: 'account.register___ru', path: '/ru/account/register', component: () => import('~/layouts/default.vue') },
		{ name: 'account.verify___ru', path: '/ru/account/verify', component: () => import('~/layouts/default.vue') },
		{ name: 'account.forget.password___ru', path: '/ru/account/forget-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.notifications___ru', path: '/ru/account/notifications', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers___ru', path: '/ru/account/customers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.show___ru', path: '/ru/account/customers/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.create___ru', path: '/ru/account/customers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.edit___ru', path: '/ru/account/customers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.index___ru', path: '/ru/account/offers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.create___ru', path: '/ru/account/offers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.edit___ru', path: '/ru/account/offers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.index___ru', path: '/ru/account/profile', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_email___ru', path: '/ru/account/profile/change-email', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_password___ru', path: '/ru/account/profile/change-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_name___ru', path: '/ru/account/profile/change-name', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.mobile_phones___ru', path: '/ru/account/profile/mobile-phones', component: () => import('~/layouts/default.vue') },
		{ name: 'sitemap.index___ru', path: '/ru/karta-sajta', component: () => import('~/layouts/default.vue') },
		{ name: 'qr_code.index___ru', path: '/ru/vqrtur', component: () => import('~/pages/desktop/qr-code/index.vue') },
		{ name: 'slug___ru', path: '/ru/:slug*', component: () => import('~/layouts/default.vue') },

		// Add routes without prefix (English).

		{ name: 'guides.index___en', path: '/guides', component: () => import('~/layouts/default.vue') },
		{ name: 'guides.show___en', path: '/guides/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'projects.index___en', path: '/projects/:location', component: () => import('~/layouts/default.vue') },
		{ name: 'contact_us___en', path: '/contact-us', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.show___en', path: '/listings/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.add___en', path: '/listings/add', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.preview___en', path: '/listings/preview', component: () => import('~/layouts/default.vue') },
		{ name: 'listings.last_seen___en', path: '/listings/last-seen', component: () => import('~/layouts/default.vue') },
		{ name: 'shared.favorite___en', path: '/shared/{hash}/favorites', component: () => import('~/layouts/default.vue') },
		{ name: 'teammate.index___en', path: '/our-team', component: () => import('~/layouts/default.vue') },
		{ name: 'developers.index___en', path: '/developers', component: () => import('~/layouts/default.vue') },
		{ name: 'developers.show___en', path: '/developers/:slug', component: () => import('~/layouts/default.vue') },
		{ name: 'more___en', path: '/more', component: () => import('~/layouts/default.vue') },
		{ name: 'search___en', path: '/search', component: () => import('~/layouts/default.vue') },
		{ name: 'account.login___en', path: '/account/login', component: () => import('~/layouts/default.vue') },
		{ name: 'account.register___en', path: '/account/register', component: () => import('~/layouts/default.vue') },
		{ name: 'account.verify___en', path: '/account/verify', component: () => import('~/layouts/default.vue') },
		{ name: 'account.forget.password___en', path: '/account/forget-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.notifications___en', path: '/account/notifications', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers___en', path: '/account/customers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.show___en', path: '/account/customers/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.create___en', path: '/account/customers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.customers.edit___en', path: '/account/customers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.index___en', path: '/account/offers', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.create___en', path: '/account/offers/create', component: () => import('~/layouts/default.vue') },
		{ name: 'account.offers.edit___en', path: '/account/offers/edit/:id', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.index___en', path: '/account/profile', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_email___en', path: '/account/profile/change-email', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_password___en', path: '/account/profile/change-password', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_name___en', path: '/account/profile/change-name', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.change_name___en', path: '/account/profile/change-name', component: () => import('~/layouts/default.vue') },
		{ name: 'account.profile.mobile_phones___en', path: '/account/profile/mobile-phones', component: () => import('~/layouts/default.vue') },
		{ name: 'sitemap.index___en', path: '/sitemaps', component: () => import('~/layouts/default.vue') },
		{ name: 'one.index___en', path: '/one', component: () => import('~/pages/desktop/sitemaps/index.vue') },
		{ name: 'qr_code.index___en', path: '/vqrtur', component: () => import('~/pages/desktop/qr-code/index.vue') },
		{ name: 'slug___en', path: '/:slug*', component: () => import('~/layouts/default.vue') },
	],
};

