<script setup></script>
<template>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.search_results')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>

    <div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mb-8">
      <div class="py-10 flex flex-col gap-3">
        <img src="~/assets/img/search-empty.jpg" alt="" class=" h-44 rounded-full object-cover mb-3 self-center">
        <h2 class="text-slate-900  text-2xl text-center">
          <span class="font-bold text-indigo-800">{{ $t('client.no_ad_found') }}</span> <br> {{ $t('client.that_match_the_search') }}
        </h2>
        <p class="text-base text-slate-600 mt-3 text-center">{{ $t('client.no_listing_sub_title') }}</p>
        <div class="flex gap-4 justify-center mt-5">
          <NuxtLink :to="localePath({ name: 'search' })" class="text-indigo-900 py-2 px-8 text-center text-lg bg-white rounded-lg border border-gray-200 hover:bg-gray-50">{{ $t('client.go_to_search') }}</NuxtLink>
          <NuxtLink :to="localePath({ name: 'home' })" class="bg-indigo-900 py-2 px-8 text-center text-lg text-white rounded-lg hover:bg-indigo-800">{{ $t('client.back_to_home') }}</NuxtLink>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>