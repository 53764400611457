import { default as indexzmIhXR0BNCMeta } from "/root/apps/web/pages/desktop/blog/index.vue?macro=true";
import { default as ad_45bannerrgMumC4AJdMeta } from "/root/apps/web/pages/desktop/blog/partials/ad-banner.vue?macro=true";
import { default as blog_45cardtZm0E0ma5WMeta } from "/root/apps/web/pages/desktop/blog/partials/blog-card.vue?macro=true";
import { default as blog_45header_45cardIPBbfjVGc3Meta } from "/root/apps/web/pages/desktop/blog/partials/blog-header-card.vue?macro=true";
import { default as commentsTJughEDbRMMeta } from "/root/apps/web/pages/desktop/blog/partials/comments.vue?macro=true";
import { default as header_45linksgiFVLxYZYUMeta } from "/root/apps/web/pages/desktop/blog/partials/header-links.vue?macro=true";
import { default as subscribe_45paneluHb2SCVxiwMeta } from "/root/apps/web/pages/desktop/blog/partials/subscribe-panel.vue?macro=true";
import { default as showwCnzYwV4w7Meta } from "/root/apps/web/pages/desktop/blog/show.vue?macro=true";
import { default as indexr0Hxr32aSGMeta } from "/root/apps/web/pages/desktop/contact/index.vue?macro=true";
import { default as indexASTsZ3lRi8Meta } from "/root/apps/web/pages/desktop/developers/index.vue?macro=true";
import { default as showa3ULIdDdpIMeta } from "/root/apps/web/pages/desktop/developers/show.vue?macro=true";
import { default as countryFSoGRExU10Meta } from "/root/apps/web/pages/desktop/guides/components/country.vue?macro=true";
import { default as detailsfgAUhbZsEcMeta } from "/root/apps/web/pages/desktop/guides/components/details.vue?macro=true";
import { default as indexkhGNq3fuuEMeta } from "/root/apps/web/pages/desktop/guides/index.vue?macro=true";
import { default as show8FTILnMHotMeta } from "/root/apps/web/pages/desktop/guides/show.vue?macro=true";
import { default as indexlSec26vhYJMeta } from "/root/apps/web/pages/desktop/home/index.vue?macro=true";
import { default as agentshhngbEk2VHMeta } from "/root/apps/web/pages/desktop/home/partials/agents.vue?macro=true";
import { default as banner3RPUe5KDRSMeta } from "/root/apps/web/pages/desktop/home/partials/banner.vue?macro=true";
import { default as become_45agentfWVaCox0tDMeta } from "/root/apps/web/pages/desktop/home/partials/become-agent.vue?macro=true";
import { default as discount_45listingsG2E1OGNoxFMeta } from "/root/apps/web/pages/desktop/home/partials/discount-listings.vue?macro=true";
import { default as filterYVcmsgrTEdMeta } from "/root/apps/web/pages/desktop/home/partials/filter.vue?macro=true";
import { default as heroTgNmXE3mUVMeta } from "/root/apps/web/pages/desktop/home/partials/hero.vue?macro=true";
import { default as latest_45listingsTQ4aFjvxD1Meta } from "/root/apps/web/pages/desktop/home/partials/latest-listings.vue?macro=true";
import { default as top_45ten_45listingsDuSJ4UZis8Meta } from "/root/apps/web/pages/desktop/home/partials/top-ten-listings.vue?macro=true";
import { default as indexKnkPBnehPQMeta } from "/root/apps/web/pages/desktop/listings/index.vue?macro=true";
import { default as add_45to_45favorite_45searchVeLmzJbMUyMeta } from "/root/apps/web/pages/desktop/listings/partials/add-to-favorite-search.vue?macro=true";
import { default as categories_45sidebarne1yPy75qGMeta } from "/root/apps/web/pages/desktop/listings/partials/categories-sidebar.vue?macro=true";
import { default as favorite_45cardFYlKzxCTQpMeta } from "/root/apps/web/pages/desktop/listings/partials/favorite-card.vue?macro=true";
import { default as favorite_45searchuy3ebKmRCXMeta } from "/root/apps/web/pages/desktop/listings/partials/favorite-search.vue?macro=true";
import { default as filter_45checkbox0FEAmbdw4kMeta } from "/root/apps/web/pages/desktop/listings/partials/filters/filter-checkbox.vue?macro=true";
import { default as filter_45elementz8OY9HC2vAMeta } from "/root/apps/web/pages/desktop/listings/partials/filters/filter-element.vue?macro=true";
import { default as filter_45locationoxryssW1x4Meta } from "/root/apps/web/pages/desktop/listings/partials/filters/filter-location.vue?macro=true";
import { default as filter_45radioipi4OK8JMsMeta } from "/root/apps/web/pages/desktop/listings/partials/filters/filter-radio.vue?macro=true";
import { default as filter_45rangemmujYBfdb2Meta } from "/root/apps/web/pages/desktop/listings/partials/filters/filter-range.vue?macro=true";
import { default as grid_45view5Bn4GS10qoMeta } from "/root/apps/web/pages/desktop/listings/partials/grid-view.vue?macro=true";
import { default as reasons_45topbarRqUBGTwM3oMeta } from "/root/apps/web/pages/desktop/listings/partials/reasons-topbar.vue?macro=true";
import { default as sort_45buttonefAXpBXkIFMeta } from "/root/apps/web/pages/desktop/listings/partials/sort-button.vue?macro=true";
import { default as table_45viewhNSpLJfBNnMeta } from "/root/apps/web/pages/desktop/listings/partials/table-view.vue?macro=true";
import { default as showKDIJ7wkq59Meta } from "/root/apps/web/pages/desktop/listings/show.vue?macro=true";
import { default as show3mLO47SwA4Meta } from "/root/apps/web/pages/desktop/pages/show.vue?macro=true";
import { default as indexoo10IGF5ehMeta } from "/root/apps/web/pages/desktop/projects/index.vue?macro=true";
import { default as listBBf5ZdwFcaMeta } from "/root/apps/web/pages/desktop/projects/list.vue?macro=true";
import { default as contact_45modalcFKR1vA11TMeta } from "/root/apps/web/pages/desktop/projects/partials/contact-modal.vue?macro=true";
import { default as filter_45checkboxjwgeowBtEkMeta } from "/root/apps/web/pages/desktop/projects/partials/filters/filter-checkbox.vue?macro=true";
import { default as filter_45element5STp3TgjZpMeta } from "/root/apps/web/pages/desktop/projects/partials/filters/filter-element.vue?macro=true";
import { default as filter_45locationD1D9mqcT1XMeta } from "/root/apps/web/pages/desktop/projects/partials/filters/filter-location.vue?macro=true";
import { default as filter_45radio2zcuSQ23e5Meta } from "/root/apps/web/pages/desktop/projects/partials/filters/filter-radio.vue?macro=true";
import { default as filter_45range9giB65Tmw7Meta } from "/root/apps/web/pages/desktop/projects/partials/filters/filter-range.vue?macro=true";
import { default as filtersTMjE0iSEgCMeta } from "/root/apps/web/pages/desktop/projects/partials/filters.vue?macro=true";
import { default as header_45menu1LYZaZUdD5Meta } from "/root/apps/web/pages/desktop/projects/partials/header-menu.vue?macro=true";
import { default as project_45cardYnWkpippOJMeta } from "/root/apps/web/pages/desktop/projects/partials/project-card.vue?macro=true";
import { default as showEN32dMYiJ3Meta } from "/root/apps/web/pages/desktop/projects/show.vue?macro=true";
import { default as indexAQbIUBpgtKMeta } from "/root/apps/web/pages/desktop/qr-code/index.vue?macro=true";
import { default as index4NPyBLwif9Meta } from "/root/apps/web/pages/desktop/sitemaps/index.vue?macro=true";
import { default as indexZ9lJzMcxiJMeta } from "/root/apps/web/pages/desktop/slug/index.vue?macro=true";
import { default as indexGRboIzYYcyMeta } from "/root/apps/web/pages/desktop/teammates/index.vue?macro=true";
import { default as showC2CWtHRMxpMeta } from "/root/apps/web/pages/desktop/teammates/show.vue?macro=true";
import { default as indexSeiMtLymsZMeta } from "/root/apps/web/pages/mobile/account/customers/form/index.vue?macro=true";
import { default as indexdAdaswNbCvMeta } from "/root/apps/web/pages/mobile/account/customers/index.vue?macro=true";
import { default as indexoMpCwMNeJ3Meta } from "/root/apps/web/pages/mobile/account/customers/partials/filters/index.vue?macro=true";
import { default as categoriesznyvHEhxxIMeta } from "/root/apps/web/pages/mobile/account/customers/partials/filters/partials/categories.vue?macro=true";
import { default as sourcesCDgcFBmq4LMeta } from "/root/apps/web/pages/mobile/account/customers/partials/filters/partials/sources.vue?macro=true";
import { default as mobile_45account_45customers_45card5eMYabokm2Meta } from "/root/apps/web/pages/mobile/account/customers/partials/mobile-account-customers-card.vue?macro=true";
import { default as indexLJ4wuz3JLLMeta } from "/root/apps/web/pages/mobile/account/customers/show/index.vue?macro=true";
import { default as index32DMz4wbVGMeta } from "/root/apps/web/pages/mobile/account/forget-password/index.vue?macro=true";
import { default as addxWBC6s9thBMeta } from "/root/apps/web/pages/mobile/account/listings/add.vue?macro=true";
import { default as index0kwYXRQHqNMeta } from "/root/apps/web/pages/mobile/account/listings/index.vue?macro=true";
import { default as last_45seen5uFsKqRAa8Meta } from "/root/apps/web/pages/mobile/account/listings/last-seen.vue?macro=true";
import { default as add_45formlHixFXA1hoMeta } from "/root/apps/web/pages/mobile/account/listings/partials/add-form.vue?macro=true";
import { default as categoriesHl2i4Y2DEPMeta } from "/root/apps/web/pages/mobile/account/listings/partials/categories.vue?macro=true";
import { default as favorite_45cardByVk9bCRLbMeta } from "/root/apps/web/pages/mobile/account/listings/partials/favorite-card.vue?macro=true";
import { default as listing_45cardNZUV2FDn8GMeta } from "/root/apps/web/pages/mobile/account/listings/partials/listing-card.vue?macro=true";
import { default as listing_45contactjqHnbMVbsRMeta } from "/root/apps/web/pages/mobile/account/listings/partials/listing-contact.vue?macro=true";
import { default as listing_45lists_45favoriteW8876mIgohMeta } from "/root/apps/web/pages/mobile/account/listings/partials/listing-lists-favorite.vue?macro=true";
import { default as sort_45buttonHKFswiUGIAMeta } from "/root/apps/web/pages/mobile/account/listings/partials/sort-button.vue?macro=true";
import { default as preview1Go8QYKW27Meta } from "/root/apps/web/pages/mobile/account/listings/preview.vue?macro=true";
import { default as show0XeRJk1ePjMeta } from "/root/apps/web/pages/mobile/account/listings/show.vue?macro=true";
import { default as indexmJEGf684ClMeta } from "/root/apps/web/pages/mobile/account/login/index.vue?macro=true";
import { default as indexMfqfk87XMoMeta } from "/root/apps/web/pages/mobile/account/notifications/index.vue?macro=true";
import { default as mobile_45notification_45cardNl4OM6bTRYMeta } from "/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-card.vue?macro=true";
import { default as mobile_45notification_45mark_45all_45readHlMTAtcTnaMeta } from "/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-mark-all-read.vue?macro=true";
import { default as cities_45inputUWeL25DF3eMeta } from "/root/apps/web/pages/mobile/account/offers/form/cities-input.vue?macro=true";
import { default as indexeTwCqZlGrfMeta } from "/root/apps/web/pages/mobile/account/offers/form/index.vue?macro=true";
import { default as indextMB08NGbXvMeta } from "/root/apps/web/pages/mobile/account/offers/index.vue?macro=true";
import { default as listsHmZ0egnPyMeta } from "/root/apps/web/pages/mobile/account/offers/list.vue?macro=true";
import { default as cardNU7LtY724IMeta } from "/root/apps/web/pages/mobile/account/offers/partial/card.vue?macro=true";
import { default as change_45emailUn45PHyCGhMeta } from "/root/apps/web/pages/mobile/account/profile/change-email.vue?macro=true";
import { default as change_45name8n6M8FYgapMeta } from "/root/apps/web/pages/mobile/account/profile/change-name.vue?macro=true";
import { default as change_45passwordIEaxffVd2LMeta } from "/root/apps/web/pages/mobile/account/profile/change-password.vue?macro=true";
import { default as index9vA5e6qqTvMeta } from "/root/apps/web/pages/mobile/account/profile/index.vue?macro=true";
import { default as mobile_45phonesrpNvuM1U0HMeta } from "/root/apps/web/pages/mobile/account/profile/mobile-phones.vue?macro=true";
import { default as add_45mobile_45phonevRgIuLMHDqMeta } from "/root/apps/web/pages/mobile/account/profile/partials/add-mobile-phone.vue?macro=true";
import { default as indexnxH2ZmtHZxMeta } from "/root/apps/web/pages/mobile/account/register/index.vue?macro=true";
import { default as indexI49aupV4ydMeta } from "/root/apps/web/pages/mobile/account/verify-account/index.vue?macro=true";
import { default as indexG42dG58Q8sMeta } from "/root/apps/web/pages/mobile/blogs/index.vue?macro=true";
import { default as mobile_45blog_45cardR5CUO4skmUMeta } from "/root/apps/web/pages/mobile/blogs/partials/mobile-blog-card.vue?macro=true";
import { default as mobile_45blog_45header_45card0bQs1SRkyZMeta } from "/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-card.vue?macro=true";
import { default as mobile_45blog_45header_45menusoJX6gNtRpFMeta } from "/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-menus.vue?macro=true";
import { default as mobile_45blog_45header_45tabsRXTsOS611vMeta } from "/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-tabs.vue?macro=true";
import { default as show1O6wCYlEnoMeta } from "/root/apps/web/pages/mobile/blogs/show.vue?macro=true";
import { default as indexlfNgYD1aAPMeta } from "/root/apps/web/pages/mobile/contact/index.vue?macro=true";
import { default as indexYUtb6upgSuMeta } from "/root/apps/web/pages/mobile/developers/index.vue?macro=true";
import { default as showUQqs8nyDgEMeta } from "/root/apps/web/pages/mobile/developers/show.vue?macro=true";
import { default as countryiQFbda2YotMeta } from "/root/apps/web/pages/mobile/guides/partials/country.vue?macro=true";
import { default as detailsUeyjkdBAbWMeta } from "/root/apps/web/pages/mobile/guides/partials/details.vue?macro=true";
import { default as showrVxQYiTloMMeta } from "/root/apps/web/pages/mobile/guides/show.vue?macro=true";
import { default as indexfElkw6h43NMeta } from "/root/apps/web/pages/mobile/home/index.vue?macro=true";
import { default as categories3OzDlpMcjWMeta } from "/root/apps/web/pages/mobile/home/partials/categories.vue?macro=true";
import { default as heroMlnKJRRFS5Meta } from "/root/apps/web/pages/mobile/home/partials/hero.vue?macro=true";
import { default as projectsxAsAyoCom2Meta } from "/root/apps/web/pages/mobile/home/partials/projects.vue?macro=true";
import { default as indexpXYFa7G9k7Meta } from "/root/apps/web/pages/mobile/more/index.vue?macro=true";
import { default as accountSf91y5M7N5Meta } from "/root/apps/web/pages/mobile/more/partials/account.vue?macro=true";
import { default as upload_45imagelUXXBgf1lCMeta } from "/root/apps/web/pages/mobile/more/partials/upload-image.vue?macro=true";
import { default as indexkcldwNGDwAMeta } from "/root/apps/web/pages/mobile/projects/index.vue?macro=true";
import { default as list3WWXTC5L2HMeta } from "/root/apps/web/pages/mobile/projects/list.vue?macro=true";
import { default as contact_45modalOEufKZml9KMeta } from "/root/apps/web/pages/mobile/projects/partials/contact-modal.vue?macro=true";
import { default as checkboxmZX6XyBnbUMeta } from "/root/apps/web/pages/mobile/projects/partials/filters/checkbox.vue?macro=true";
import { default as elementizJxxVUwXpMeta } from "/root/apps/web/pages/mobile/projects/partials/filters/element.vue?macro=true";
import { default as locationrbvRURqgCCMeta } from "/root/apps/web/pages/mobile/projects/partials/filters/location.vue?macro=true";
import { default as radiowfHE3KT6kEMeta } from "/root/apps/web/pages/mobile/projects/partials/filters/radio.vue?macro=true";
import { default as rangeF9LD6I8fXqMeta } from "/root/apps/web/pages/mobile/projects/partials/filters/range.vue?macro=true";
import { default as filtersxhqISZM50zMeta } from "/root/apps/web/pages/mobile/projects/partials/filters.vue?macro=true";
import { default as project_45cardBpGNA09u5MMeta } from "/root/apps/web/pages/mobile/projects/partials/project-card.vue?macro=true";
import { default as sortGXiMflk9IdMeta } from "/root/apps/web/pages/mobile/projects/partials/sort.vue?macro=true";
import { default as showInOeBdSqV5Meta } from "/root/apps/web/pages/mobile/projects/show.vue?macro=true";
import { default as indexrDr79BO0NrMeta } from "/root/apps/web/pages/mobile/search/index.vue?macro=true";
import { default as checkboxW2xvjISw3tMeta } from "/root/apps/web/pages/mobile/search/partials/filters/checkbox.vue?macro=true";
import { default as elementGgdhuFYWKAMeta } from "/root/apps/web/pages/mobile/search/partials/filters/element.vue?macro=true";
import { default as locationNwzekx7notMeta } from "/root/apps/web/pages/mobile/search/partials/filters/location.vue?macro=true";
import { default as multi_45tapsQKpHGxkWSXMeta } from "/root/apps/web/pages/mobile/search/partials/filters/multi-taps.vue?macro=true";
import { default as radio5mSqOUBCocMeta } from "/root/apps/web/pages/mobile/search/partials/filters/radio.vue?macro=true";
import { default as range4u1kTxVBq3Meta } from "/root/apps/web/pages/mobile/search/partials/filters/range.vue?macro=true";
import { default as search_45by_45listing_45id0DyebyoLoKMeta } from "/root/apps/web/pages/mobile/search/partials/search-by-listing-id.vue?macro=true";
import { default as indexumaBp4z23ZMeta } from "/root/apps/web/pages/mobile/sitemaps/index.vue?macro=true";
import { default as indexFIbmvtGcNRMeta } from "/root/apps/web/pages/mobile/slug/index.vue?macro=true";
import { default as index0eBgcLsg7JMeta } from "/root/apps/web/pages/mobile/teammates/index.vue?macro=true";
import { default as teammate_45cardr4deZmwKtYMeta } from "/root/apps/web/pages/mobile/teammates/partials/teammate-card.vue?macro=true";
import { default as teammate_45filter8PeDtjc6nJMeta } from "/root/apps/web/pages/mobile/teammates/partials/teammate-filter.vue?macro=true";
import { default as showYEDtpCXRBcMeta } from "/root/apps/web/pages/mobile/teammates/show.vue?macro=true";
export default [
  {
    name: "desktop-blog___en",
    path: "/desktop/blog",
    component: () => import("/root/apps/web/pages/desktop/blog/index.vue")
  },
  {
    name: "desktop-blog___tr",
    path: "/tr/desktop/blog",
    component: () => import("/root/apps/web/pages/desktop/blog/index.vue")
  },
  {
    name: "desktop-blog___ru",
    path: "/ru/desktop/blog",
    component: () => import("/root/apps/web/pages/desktop/blog/index.vue")
  },
  {
    name: "desktop-blog___fa",
    path: "/fa/desktop/blog",
    component: () => import("/root/apps/web/pages/desktop/blog/index.vue")
  },
  {
    name: "desktop-blog___ar",
    path: "/ar/desktop/blog",
    component: () => import("/root/apps/web/pages/desktop/blog/index.vue")
  },
  {
    name: "desktop-blog-partials-ad-banner___en",
    path: "/desktop/blog/partials/ad-banner",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/ad-banner.vue")
  },
  {
    name: "desktop-blog-partials-ad-banner___tr",
    path: "/tr/desktop/blog/partials/ad-banner",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/ad-banner.vue")
  },
  {
    name: "desktop-blog-partials-ad-banner___ru",
    path: "/ru/desktop/blog/partials/ad-banner",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/ad-banner.vue")
  },
  {
    name: "desktop-blog-partials-ad-banner___fa",
    path: "/fa/desktop/blog/partials/ad-banner",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/ad-banner.vue")
  },
  {
    name: "desktop-blog-partials-ad-banner___ar",
    path: "/ar/desktop/blog/partials/ad-banner",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/ad-banner.vue")
  },
  {
    name: "desktop-blog-partials-blog-card___en",
    path: "/desktop/blog/partials/blog-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-card.vue")
  },
  {
    name: "desktop-blog-partials-blog-card___tr",
    path: "/tr/desktop/blog/partials/blog-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-card.vue")
  },
  {
    name: "desktop-blog-partials-blog-card___ru",
    path: "/ru/desktop/blog/partials/blog-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-card.vue")
  },
  {
    name: "desktop-blog-partials-blog-card___fa",
    path: "/fa/desktop/blog/partials/blog-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-card.vue")
  },
  {
    name: "desktop-blog-partials-blog-card___ar",
    path: "/ar/desktop/blog/partials/blog-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-card.vue")
  },
  {
    name: "desktop-blog-partials-blog-header-card___en",
    path: "/desktop/blog/partials/blog-header-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-header-card.vue")
  },
  {
    name: "desktop-blog-partials-blog-header-card___tr",
    path: "/tr/desktop/blog/partials/blog-header-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-header-card.vue")
  },
  {
    name: "desktop-blog-partials-blog-header-card___ru",
    path: "/ru/desktop/blog/partials/blog-header-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-header-card.vue")
  },
  {
    name: "desktop-blog-partials-blog-header-card___fa",
    path: "/fa/desktop/blog/partials/blog-header-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-header-card.vue")
  },
  {
    name: "desktop-blog-partials-blog-header-card___ar",
    path: "/ar/desktop/blog/partials/blog-header-card",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/blog-header-card.vue")
  },
  {
    name: "desktop-blog-partials-comments___en",
    path: "/desktop/blog/partials/comments",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/comments.vue")
  },
  {
    name: "desktop-blog-partials-comments___tr",
    path: "/tr/desktop/blog/partials/comments",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/comments.vue")
  },
  {
    name: "desktop-blog-partials-comments___ru",
    path: "/ru/desktop/blog/partials/comments",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/comments.vue")
  },
  {
    name: "desktop-blog-partials-comments___fa",
    path: "/fa/desktop/blog/partials/comments",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/comments.vue")
  },
  {
    name: "desktop-blog-partials-comments___ar",
    path: "/ar/desktop/blog/partials/comments",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/comments.vue")
  },
  {
    name: "desktop-blog-partials-header-links___en",
    path: "/desktop/blog/partials/header-links",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/header-links.vue")
  },
  {
    name: "desktop-blog-partials-header-links___tr",
    path: "/tr/desktop/blog/partials/header-links",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/header-links.vue")
  },
  {
    name: "desktop-blog-partials-header-links___ru",
    path: "/ru/desktop/blog/partials/header-links",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/header-links.vue")
  },
  {
    name: "desktop-blog-partials-header-links___fa",
    path: "/fa/desktop/blog/partials/header-links",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/header-links.vue")
  },
  {
    name: "desktop-blog-partials-header-links___ar",
    path: "/ar/desktop/blog/partials/header-links",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/header-links.vue")
  },
  {
    name: "desktop-blog-partials-subscribe-panel___en",
    path: "/desktop/blog/partials/subscribe-panel",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/subscribe-panel.vue")
  },
  {
    name: "desktop-blog-partials-subscribe-panel___tr",
    path: "/tr/desktop/blog/partials/subscribe-panel",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/subscribe-panel.vue")
  },
  {
    name: "desktop-blog-partials-subscribe-panel___ru",
    path: "/ru/desktop/blog/partials/subscribe-panel",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/subscribe-panel.vue")
  },
  {
    name: "desktop-blog-partials-subscribe-panel___fa",
    path: "/fa/desktop/blog/partials/subscribe-panel",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/subscribe-panel.vue")
  },
  {
    name: "desktop-blog-partials-subscribe-panel___ar",
    path: "/ar/desktop/blog/partials/subscribe-panel",
    component: () => import("/root/apps/web/pages/desktop/blog/partials/subscribe-panel.vue")
  },
  {
    name: "desktop-blog-show___en",
    path: "/desktop/blog/show",
    component: () => import("/root/apps/web/pages/desktop/blog/show.vue")
  },
  {
    name: "desktop-blog-show___tr",
    path: "/tr/desktop/blog/show",
    component: () => import("/root/apps/web/pages/desktop/blog/show.vue")
  },
  {
    name: "desktop-blog-show___ru",
    path: "/ru/desktop/blog/show",
    component: () => import("/root/apps/web/pages/desktop/blog/show.vue")
  },
  {
    name: "desktop-blog-show___fa",
    path: "/fa/desktop/blog/show",
    component: () => import("/root/apps/web/pages/desktop/blog/show.vue")
  },
  {
    name: "desktop-blog-show___ar",
    path: "/ar/desktop/blog/show",
    component: () => import("/root/apps/web/pages/desktop/blog/show.vue")
  },
  {
    name: "desktop-contact___en",
    path: "/desktop/contact",
    component: () => import("/root/apps/web/pages/desktop/contact/index.vue")
  },
  {
    name: "desktop-contact___tr",
    path: "/tr/desktop/contact",
    component: () => import("/root/apps/web/pages/desktop/contact/index.vue")
  },
  {
    name: "desktop-contact___ru",
    path: "/ru/desktop/contact",
    component: () => import("/root/apps/web/pages/desktop/contact/index.vue")
  },
  {
    name: "desktop-contact___fa",
    path: "/fa/desktop/contact",
    component: () => import("/root/apps/web/pages/desktop/contact/index.vue")
  },
  {
    name: "desktop-contact___ar",
    path: "/ar/desktop/contact",
    component: () => import("/root/apps/web/pages/desktop/contact/index.vue")
  },
  {
    name: "desktop-developers___en",
    path: "/desktop/developers",
    component: () => import("/root/apps/web/pages/desktop/developers/index.vue")
  },
  {
    name: "desktop-developers___tr",
    path: "/tr/desktop/developers",
    component: () => import("/root/apps/web/pages/desktop/developers/index.vue")
  },
  {
    name: "desktop-developers___ru",
    path: "/ru/desktop/developers",
    component: () => import("/root/apps/web/pages/desktop/developers/index.vue")
  },
  {
    name: "desktop-developers___fa",
    path: "/fa/desktop/developers",
    component: () => import("/root/apps/web/pages/desktop/developers/index.vue")
  },
  {
    name: "desktop-developers___ar",
    path: "/ar/desktop/developers",
    component: () => import("/root/apps/web/pages/desktop/developers/index.vue")
  },
  {
    name: "desktop-developers-show___en",
    path: "/desktop/developers/show",
    component: () => import("/root/apps/web/pages/desktop/developers/show.vue")
  },
  {
    name: "desktop-developers-show___tr",
    path: "/tr/desktop/developers/show",
    component: () => import("/root/apps/web/pages/desktop/developers/show.vue")
  },
  {
    name: "desktop-developers-show___ru",
    path: "/ru/desktop/developers/show",
    component: () => import("/root/apps/web/pages/desktop/developers/show.vue")
  },
  {
    name: "desktop-developers-show___fa",
    path: "/fa/desktop/developers/show",
    component: () => import("/root/apps/web/pages/desktop/developers/show.vue")
  },
  {
    name: "desktop-developers-show___ar",
    path: "/ar/desktop/developers/show",
    component: () => import("/root/apps/web/pages/desktop/developers/show.vue")
  },
  {
    name: "desktop-guides-components-country___en",
    path: "/desktop/guides/components/country",
    component: () => import("/root/apps/web/pages/desktop/guides/components/country.vue")
  },
  {
    name: "desktop-guides-components-country___tr",
    path: "/tr/desktop/guides/components/country",
    component: () => import("/root/apps/web/pages/desktop/guides/components/country.vue")
  },
  {
    name: "desktop-guides-components-country___ru",
    path: "/ru/desktop/guides/components/country",
    component: () => import("/root/apps/web/pages/desktop/guides/components/country.vue")
  },
  {
    name: "desktop-guides-components-country___fa",
    path: "/fa/desktop/guides/components/country",
    component: () => import("/root/apps/web/pages/desktop/guides/components/country.vue")
  },
  {
    name: "desktop-guides-components-country___ar",
    path: "/ar/desktop/guides/components/country",
    component: () => import("/root/apps/web/pages/desktop/guides/components/country.vue")
  },
  {
    name: "desktop-guides-components-details___en",
    path: "/desktop/guides/components/details",
    component: () => import("/root/apps/web/pages/desktop/guides/components/details.vue")
  },
  {
    name: "desktop-guides-components-details___tr",
    path: "/tr/desktop/guides/components/details",
    component: () => import("/root/apps/web/pages/desktop/guides/components/details.vue")
  },
  {
    name: "desktop-guides-components-details___ru",
    path: "/ru/desktop/guides/components/details",
    component: () => import("/root/apps/web/pages/desktop/guides/components/details.vue")
  },
  {
    name: "desktop-guides-components-details___fa",
    path: "/fa/desktop/guides/components/details",
    component: () => import("/root/apps/web/pages/desktop/guides/components/details.vue")
  },
  {
    name: "desktop-guides-components-details___ar",
    path: "/ar/desktop/guides/components/details",
    component: () => import("/root/apps/web/pages/desktop/guides/components/details.vue")
  },
  {
    name: "desktop-guides___en",
    path: "/desktop/guides",
    component: () => import("/root/apps/web/pages/desktop/guides/index.vue")
  },
  {
    name: "desktop-guides___tr",
    path: "/tr/desktop/guides",
    component: () => import("/root/apps/web/pages/desktop/guides/index.vue")
  },
  {
    name: "desktop-guides___ru",
    path: "/ru/desktop/guides",
    component: () => import("/root/apps/web/pages/desktop/guides/index.vue")
  },
  {
    name: "desktop-guides___fa",
    path: "/fa/desktop/guides",
    component: () => import("/root/apps/web/pages/desktop/guides/index.vue")
  },
  {
    name: "desktop-guides___ar",
    path: "/ar/desktop/guides",
    component: () => import("/root/apps/web/pages/desktop/guides/index.vue")
  },
  {
    name: "desktop-guides-show___en",
    path: "/desktop/guides/show",
    component: () => import("/root/apps/web/pages/desktop/guides/show.vue")
  },
  {
    name: "desktop-guides-show___tr",
    path: "/tr/desktop/guides/show",
    component: () => import("/root/apps/web/pages/desktop/guides/show.vue")
  },
  {
    name: "desktop-guides-show___ru",
    path: "/ru/desktop/guides/show",
    component: () => import("/root/apps/web/pages/desktop/guides/show.vue")
  },
  {
    name: "desktop-guides-show___fa",
    path: "/fa/desktop/guides/show",
    component: () => import("/root/apps/web/pages/desktop/guides/show.vue")
  },
  {
    name: "desktop-guides-show___ar",
    path: "/ar/desktop/guides/show",
    component: () => import("/root/apps/web/pages/desktop/guides/show.vue")
  },
  {
    name: "desktop-home___en",
    path: "/desktop/home",
    component: () => import("/root/apps/web/pages/desktop/home/index.vue")
  },
  {
    name: "desktop-home___tr",
    path: "/tr/desktop/home",
    component: () => import("/root/apps/web/pages/desktop/home/index.vue")
  },
  {
    name: "desktop-home___ru",
    path: "/ru/desktop/home",
    component: () => import("/root/apps/web/pages/desktop/home/index.vue")
  },
  {
    name: "desktop-home___fa",
    path: "/fa/desktop/home",
    component: () => import("/root/apps/web/pages/desktop/home/index.vue")
  },
  {
    name: "desktop-home___ar",
    path: "/ar/desktop/home",
    component: () => import("/root/apps/web/pages/desktop/home/index.vue")
  },
  {
    name: "desktop-home-partials-agents___en",
    path: "/desktop/home/partials/agents",
    component: () => import("/root/apps/web/pages/desktop/home/partials/agents.vue")
  },
  {
    name: "desktop-home-partials-agents___tr",
    path: "/tr/desktop/home/partials/agents",
    component: () => import("/root/apps/web/pages/desktop/home/partials/agents.vue")
  },
  {
    name: "desktop-home-partials-agents___ru",
    path: "/ru/desktop/home/partials/agents",
    component: () => import("/root/apps/web/pages/desktop/home/partials/agents.vue")
  },
  {
    name: "desktop-home-partials-agents___fa",
    path: "/fa/desktop/home/partials/agents",
    component: () => import("/root/apps/web/pages/desktop/home/partials/agents.vue")
  },
  {
    name: "desktop-home-partials-agents___ar",
    path: "/ar/desktop/home/partials/agents",
    component: () => import("/root/apps/web/pages/desktop/home/partials/agents.vue")
  },
  {
    name: "desktop-home-partials-banner___en",
    path: "/desktop/home/partials/banner",
    component: () => import("/root/apps/web/pages/desktop/home/partials/banner.vue")
  },
  {
    name: "desktop-home-partials-banner___tr",
    path: "/tr/desktop/home/partials/banner",
    component: () => import("/root/apps/web/pages/desktop/home/partials/banner.vue")
  },
  {
    name: "desktop-home-partials-banner___ru",
    path: "/ru/desktop/home/partials/banner",
    component: () => import("/root/apps/web/pages/desktop/home/partials/banner.vue")
  },
  {
    name: "desktop-home-partials-banner___fa",
    path: "/fa/desktop/home/partials/banner",
    component: () => import("/root/apps/web/pages/desktop/home/partials/banner.vue")
  },
  {
    name: "desktop-home-partials-banner___ar",
    path: "/ar/desktop/home/partials/banner",
    component: () => import("/root/apps/web/pages/desktop/home/partials/banner.vue")
  },
  {
    name: "desktop-home-partials-become-agent___en",
    path: "/desktop/home/partials/become-agent",
    component: () => import("/root/apps/web/pages/desktop/home/partials/become-agent.vue")
  },
  {
    name: "desktop-home-partials-become-agent___tr",
    path: "/tr/desktop/home/partials/become-agent",
    component: () => import("/root/apps/web/pages/desktop/home/partials/become-agent.vue")
  },
  {
    name: "desktop-home-partials-become-agent___ru",
    path: "/ru/desktop/home/partials/become-agent",
    component: () => import("/root/apps/web/pages/desktop/home/partials/become-agent.vue")
  },
  {
    name: "desktop-home-partials-become-agent___fa",
    path: "/fa/desktop/home/partials/become-agent",
    component: () => import("/root/apps/web/pages/desktop/home/partials/become-agent.vue")
  },
  {
    name: "desktop-home-partials-become-agent___ar",
    path: "/ar/desktop/home/partials/become-agent",
    component: () => import("/root/apps/web/pages/desktop/home/partials/become-agent.vue")
  },
  {
    name: "desktop-home-partials-discount-listings___en",
    path: "/desktop/home/partials/discount-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/discount-listings.vue")
  },
  {
    name: "desktop-home-partials-discount-listings___tr",
    path: "/tr/desktop/home/partials/discount-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/discount-listings.vue")
  },
  {
    name: "desktop-home-partials-discount-listings___ru",
    path: "/ru/desktop/home/partials/discount-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/discount-listings.vue")
  },
  {
    name: "desktop-home-partials-discount-listings___fa",
    path: "/fa/desktop/home/partials/discount-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/discount-listings.vue")
  },
  {
    name: "desktop-home-partials-discount-listings___ar",
    path: "/ar/desktop/home/partials/discount-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/discount-listings.vue")
  },
  {
    name: "desktop-home-partials-filter___en",
    path: "/desktop/home/partials/filter",
    component: () => import("/root/apps/web/pages/desktop/home/partials/filter.vue")
  },
  {
    name: "desktop-home-partials-filter___tr",
    path: "/tr/desktop/home/partials/filter",
    component: () => import("/root/apps/web/pages/desktop/home/partials/filter.vue")
  },
  {
    name: "desktop-home-partials-filter___ru",
    path: "/ru/desktop/home/partials/filter",
    component: () => import("/root/apps/web/pages/desktop/home/partials/filter.vue")
  },
  {
    name: "desktop-home-partials-filter___fa",
    path: "/fa/desktop/home/partials/filter",
    component: () => import("/root/apps/web/pages/desktop/home/partials/filter.vue")
  },
  {
    name: "desktop-home-partials-filter___ar",
    path: "/ar/desktop/home/partials/filter",
    component: () => import("/root/apps/web/pages/desktop/home/partials/filter.vue")
  },
  {
    name: "desktop-home-partials-hero___en",
    path: "/desktop/home/partials/hero",
    component: () => import("/root/apps/web/pages/desktop/home/partials/hero.vue")
  },
  {
    name: "desktop-home-partials-hero___tr",
    path: "/tr/desktop/home/partials/hero",
    component: () => import("/root/apps/web/pages/desktop/home/partials/hero.vue")
  },
  {
    name: "desktop-home-partials-hero___ru",
    path: "/ru/desktop/home/partials/hero",
    component: () => import("/root/apps/web/pages/desktop/home/partials/hero.vue")
  },
  {
    name: "desktop-home-partials-hero___fa",
    path: "/fa/desktop/home/partials/hero",
    component: () => import("/root/apps/web/pages/desktop/home/partials/hero.vue")
  },
  {
    name: "desktop-home-partials-hero___ar",
    path: "/ar/desktop/home/partials/hero",
    component: () => import("/root/apps/web/pages/desktop/home/partials/hero.vue")
  },
  {
    name: "desktop-home-partials-latest-listings___en",
    path: "/desktop/home/partials/latest-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/latest-listings.vue")
  },
  {
    name: "desktop-home-partials-latest-listings___tr",
    path: "/tr/desktop/home/partials/latest-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/latest-listings.vue")
  },
  {
    name: "desktop-home-partials-latest-listings___ru",
    path: "/ru/desktop/home/partials/latest-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/latest-listings.vue")
  },
  {
    name: "desktop-home-partials-latest-listings___fa",
    path: "/fa/desktop/home/partials/latest-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/latest-listings.vue")
  },
  {
    name: "desktop-home-partials-latest-listings___ar",
    path: "/ar/desktop/home/partials/latest-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/latest-listings.vue")
  },
  {
    name: "desktop-home-partials-top-ten-listings___en",
    path: "/desktop/home/partials/top-ten-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/top-ten-listings.vue")
  },
  {
    name: "desktop-home-partials-top-ten-listings___tr",
    path: "/tr/desktop/home/partials/top-ten-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/top-ten-listings.vue")
  },
  {
    name: "desktop-home-partials-top-ten-listings___ru",
    path: "/ru/desktop/home/partials/top-ten-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/top-ten-listings.vue")
  },
  {
    name: "desktop-home-partials-top-ten-listings___fa",
    path: "/fa/desktop/home/partials/top-ten-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/top-ten-listings.vue")
  },
  {
    name: "desktop-home-partials-top-ten-listings___ar",
    path: "/ar/desktop/home/partials/top-ten-listings",
    component: () => import("/root/apps/web/pages/desktop/home/partials/top-ten-listings.vue")
  },
  {
    name: "desktop-listings___en",
    path: "/desktop/listings",
    component: () => import("/root/apps/web/pages/desktop/listings/index.vue")
  },
  {
    name: "desktop-listings___tr",
    path: "/tr/desktop/listings",
    component: () => import("/root/apps/web/pages/desktop/listings/index.vue")
  },
  {
    name: "desktop-listings___ru",
    path: "/ru/desktop/listings",
    component: () => import("/root/apps/web/pages/desktop/listings/index.vue")
  },
  {
    name: "desktop-listings___fa",
    path: "/fa/desktop/listings",
    component: () => import("/root/apps/web/pages/desktop/listings/index.vue")
  },
  {
    name: "desktop-listings___ar",
    path: "/ar/desktop/listings",
    component: () => import("/root/apps/web/pages/desktop/listings/index.vue")
  },
  {
    name: "desktop-listings-partials-add-to-favorite-search___en",
    path: "/desktop/listings/partials/add-to-favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/add-to-favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-add-to-favorite-search___tr",
    path: "/tr/desktop/listings/partials/add-to-favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/add-to-favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-add-to-favorite-search___ru",
    path: "/ru/desktop/listings/partials/add-to-favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/add-to-favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-add-to-favorite-search___fa",
    path: "/fa/desktop/listings/partials/add-to-favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/add-to-favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-add-to-favorite-search___ar",
    path: "/ar/desktop/listings/partials/add-to-favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/add-to-favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-categories-sidebar___en",
    path: "/desktop/listings/partials/categories-sidebar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/categories-sidebar.vue")
  },
  {
    name: "desktop-listings-partials-categories-sidebar___tr",
    path: "/tr/desktop/listings/partials/categories-sidebar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/categories-sidebar.vue")
  },
  {
    name: "desktop-listings-partials-categories-sidebar___ru",
    path: "/ru/desktop/listings/partials/categories-sidebar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/categories-sidebar.vue")
  },
  {
    name: "desktop-listings-partials-categories-sidebar___fa",
    path: "/fa/desktop/listings/partials/categories-sidebar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/categories-sidebar.vue")
  },
  {
    name: "desktop-listings-partials-categories-sidebar___ar",
    path: "/ar/desktop/listings/partials/categories-sidebar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/categories-sidebar.vue")
  },
  {
    name: "desktop-listings-partials-favorite-card___en",
    path: "/desktop/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-card.vue")
  },
  {
    name: "desktop-listings-partials-favorite-card___tr",
    path: "/tr/desktop/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-card.vue")
  },
  {
    name: "desktop-listings-partials-favorite-card___ru",
    path: "/ru/desktop/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-card.vue")
  },
  {
    name: "desktop-listings-partials-favorite-card___fa",
    path: "/fa/desktop/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-card.vue")
  },
  {
    name: "desktop-listings-partials-favorite-card___ar",
    path: "/ar/desktop/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-card.vue")
  },
  {
    name: "desktop-listings-partials-favorite-search___en",
    path: "/desktop/listings/partials/favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-favorite-search___tr",
    path: "/tr/desktop/listings/partials/favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-favorite-search___ru",
    path: "/ru/desktop/listings/partials/favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-favorite-search___fa",
    path: "/fa/desktop/listings/partials/favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-favorite-search___ar",
    path: "/ar/desktop/listings/partials/favorite-search",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/favorite-search.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-checkbox___en",
    path: "/desktop/listings/partials/filters/filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-checkbox___tr",
    path: "/tr/desktop/listings/partials/filters/filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-checkbox___ru",
    path: "/ru/desktop/listings/partials/filters/filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-checkbox___fa",
    path: "/fa/desktop/listings/partials/filters/filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-checkbox___ar",
    path: "/ar/desktop/listings/partials/filters/filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-element___en",
    path: "/desktop/listings/partials/filters/filter-element",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-element___tr",
    path: "/tr/desktop/listings/partials/filters/filter-element",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-element___ru",
    path: "/ru/desktop/listings/partials/filters/filter-element",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-element___fa",
    path: "/fa/desktop/listings/partials/filters/filter-element",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-element___ar",
    path: "/ar/desktop/listings/partials/filters/filter-element",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-location___en",
    path: "/desktop/listings/partials/filters/filter-location",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-location___tr",
    path: "/tr/desktop/listings/partials/filters/filter-location",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-location___ru",
    path: "/ru/desktop/listings/partials/filters/filter-location",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-location___fa",
    path: "/fa/desktop/listings/partials/filters/filter-location",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-location___ar",
    path: "/ar/desktop/listings/partials/filters/filter-location",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-radio___en",
    path: "/desktop/listings/partials/filters/filter-radio",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-radio___tr",
    path: "/tr/desktop/listings/partials/filters/filter-radio",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-radio___ru",
    path: "/ru/desktop/listings/partials/filters/filter-radio",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-radio___fa",
    path: "/fa/desktop/listings/partials/filters/filter-radio",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-radio___ar",
    path: "/ar/desktop/listings/partials/filters/filter-radio",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-range___en",
    path: "/desktop/listings/partials/filters/filter-range",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-range.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-range___tr",
    path: "/tr/desktop/listings/partials/filters/filter-range",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-range.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-range___ru",
    path: "/ru/desktop/listings/partials/filters/filter-range",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-range.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-range___fa",
    path: "/fa/desktop/listings/partials/filters/filter-range",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-range.vue")
  },
  {
    name: "desktop-listings-partials-filters-filter-range___ar",
    path: "/ar/desktop/listings/partials/filters/filter-range",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/filters/filter-range.vue")
  },
  {
    name: "desktop-listings-partials-grid-view___en",
    path: "/desktop/listings/partials/grid-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/grid-view.vue")
  },
  {
    name: "desktop-listings-partials-grid-view___tr",
    path: "/tr/desktop/listings/partials/grid-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/grid-view.vue")
  },
  {
    name: "desktop-listings-partials-grid-view___ru",
    path: "/ru/desktop/listings/partials/grid-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/grid-view.vue")
  },
  {
    name: "desktop-listings-partials-grid-view___fa",
    path: "/fa/desktop/listings/partials/grid-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/grid-view.vue")
  },
  {
    name: "desktop-listings-partials-grid-view___ar",
    path: "/ar/desktop/listings/partials/grid-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/grid-view.vue")
  },
  {
    name: "desktop-listings-partials-reasons-topbar___en",
    path: "/desktop/listings/partials/reasons-topbar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/reasons-topbar.vue")
  },
  {
    name: "desktop-listings-partials-reasons-topbar___tr",
    path: "/tr/desktop/listings/partials/reasons-topbar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/reasons-topbar.vue")
  },
  {
    name: "desktop-listings-partials-reasons-topbar___ru",
    path: "/ru/desktop/listings/partials/reasons-topbar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/reasons-topbar.vue")
  },
  {
    name: "desktop-listings-partials-reasons-topbar___fa",
    path: "/fa/desktop/listings/partials/reasons-topbar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/reasons-topbar.vue")
  },
  {
    name: "desktop-listings-partials-reasons-topbar___ar",
    path: "/ar/desktop/listings/partials/reasons-topbar",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/reasons-topbar.vue")
  },
  {
    name: "desktop-listings-partials-sort-button___en",
    path: "/desktop/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/sort-button.vue")
  },
  {
    name: "desktop-listings-partials-sort-button___tr",
    path: "/tr/desktop/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/sort-button.vue")
  },
  {
    name: "desktop-listings-partials-sort-button___ru",
    path: "/ru/desktop/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/sort-button.vue")
  },
  {
    name: "desktop-listings-partials-sort-button___fa",
    path: "/fa/desktop/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/sort-button.vue")
  },
  {
    name: "desktop-listings-partials-sort-button___ar",
    path: "/ar/desktop/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/sort-button.vue")
  },
  {
    name: "desktop-listings-partials-table-view___en",
    path: "/desktop/listings/partials/table-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/table-view.vue")
  },
  {
    name: "desktop-listings-partials-table-view___tr",
    path: "/tr/desktop/listings/partials/table-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/table-view.vue")
  },
  {
    name: "desktop-listings-partials-table-view___ru",
    path: "/ru/desktop/listings/partials/table-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/table-view.vue")
  },
  {
    name: "desktop-listings-partials-table-view___fa",
    path: "/fa/desktop/listings/partials/table-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/table-view.vue")
  },
  {
    name: "desktop-listings-partials-table-view___ar",
    path: "/ar/desktop/listings/partials/table-view",
    component: () => import("/root/apps/web/pages/desktop/listings/partials/table-view.vue")
  },
  {
    name: "desktop-listings-show___en",
    path: "/desktop/listings/show",
    component: () => import("/root/apps/web/pages/desktop/listings/show.vue")
  },
  {
    name: "desktop-listings-show___tr",
    path: "/tr/desktop/listings/show",
    component: () => import("/root/apps/web/pages/desktop/listings/show.vue")
  },
  {
    name: "desktop-listings-show___ru",
    path: "/ru/desktop/listings/show",
    component: () => import("/root/apps/web/pages/desktop/listings/show.vue")
  },
  {
    name: "desktop-listings-show___fa",
    path: "/fa/desktop/listings/show",
    component: () => import("/root/apps/web/pages/desktop/listings/show.vue")
  },
  {
    name: "desktop-listings-show___ar",
    path: "/ar/desktop/listings/show",
    component: () => import("/root/apps/web/pages/desktop/listings/show.vue")
  },
  {
    name: "desktop-pages-show___en",
    path: "/desktop/pages/show",
    component: () => import("/root/apps/web/pages/desktop/pages/show.vue")
  },
  {
    name: "desktop-pages-show___tr",
    path: "/tr/desktop/pages/show",
    component: () => import("/root/apps/web/pages/desktop/pages/show.vue")
  },
  {
    name: "desktop-pages-show___ru",
    path: "/ru/desktop/pages/show",
    component: () => import("/root/apps/web/pages/desktop/pages/show.vue")
  },
  {
    name: "desktop-pages-show___fa",
    path: "/fa/desktop/pages/show",
    component: () => import("/root/apps/web/pages/desktop/pages/show.vue")
  },
  {
    name: "desktop-pages-show___ar",
    path: "/ar/desktop/pages/show",
    component: () => import("/root/apps/web/pages/desktop/pages/show.vue")
  },
  {
    name: "desktop-projects___en",
    path: "/desktop/projects",
    component: () => import("/root/apps/web/pages/desktop/projects/index.vue")
  },
  {
    name: "desktop-projects___tr",
    path: "/tr/desktop/projects",
    component: () => import("/root/apps/web/pages/desktop/projects/index.vue")
  },
  {
    name: "desktop-projects___ru",
    path: "/ru/desktop/projects",
    component: () => import("/root/apps/web/pages/desktop/projects/index.vue")
  },
  {
    name: "desktop-projects___fa",
    path: "/fa/desktop/projects",
    component: () => import("/root/apps/web/pages/desktop/projects/index.vue")
  },
  {
    name: "desktop-projects___ar",
    path: "/ar/desktop/projects",
    component: () => import("/root/apps/web/pages/desktop/projects/index.vue")
  },
  {
    name: "desktop-projects-list___en",
    path: "/desktop/projects/list",
    component: () => import("/root/apps/web/pages/desktop/projects/list.vue")
  },
  {
    name: "desktop-projects-list___tr",
    path: "/tr/desktop/projects/list",
    component: () => import("/root/apps/web/pages/desktop/projects/list.vue")
  },
  {
    name: "desktop-projects-list___ru",
    path: "/ru/desktop/projects/list",
    component: () => import("/root/apps/web/pages/desktop/projects/list.vue")
  },
  {
    name: "desktop-projects-list___fa",
    path: "/fa/desktop/projects/list",
    component: () => import("/root/apps/web/pages/desktop/projects/list.vue")
  },
  {
    name: "desktop-projects-list___ar",
    path: "/ar/desktop/projects/list",
    component: () => import("/root/apps/web/pages/desktop/projects/list.vue")
  },
  {
    name: "desktop-projects-partials-contact-modal___en",
    path: "/desktop/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/contact-modal.vue")
  },
  {
    name: "desktop-projects-partials-contact-modal___tr",
    path: "/tr/desktop/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/contact-modal.vue")
  },
  {
    name: "desktop-projects-partials-contact-modal___ru",
    path: "/ru/desktop/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/contact-modal.vue")
  },
  {
    name: "desktop-projects-partials-contact-modal___fa",
    path: "/fa/desktop/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/contact-modal.vue")
  },
  {
    name: "desktop-projects-partials-contact-modal___ar",
    path: "/ar/desktop/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/contact-modal.vue")
  },
  {
    name: "desktop-projects-partials-filters___en",
    path: "/desktop/projects/partials/filters",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters.vue"),
    children: [
  {
    name: "desktop-projects-partials-filters-filter-checkbox___en",
    path: "filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-element___en",
    path: "filter-element",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-location___en",
    path: "filter-location",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-radio___en",
    path: "filter-radio",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-range___en",
    path: "filter-range",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-range.vue")
  }
]
  },
  {
    name: "desktop-projects-partials-filters___tr",
    path: "/tr/desktop/projects/partials/filters",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters.vue"),
    children: [
  {
    name: "desktop-projects-partials-filters-filter-checkbox___tr",
    path: "filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-element___tr",
    path: "filter-element",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-location___tr",
    path: "filter-location",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-radio___tr",
    path: "filter-radio",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-range___tr",
    path: "filter-range",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-range.vue")
  }
]
  },
  {
    name: "desktop-projects-partials-filters___ru",
    path: "/ru/desktop/projects/partials/filters",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters.vue"),
    children: [
  {
    name: "desktop-projects-partials-filters-filter-checkbox___ru",
    path: "filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-element___ru",
    path: "filter-element",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-location___ru",
    path: "filter-location",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-radio___ru",
    path: "filter-radio",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-range___ru",
    path: "filter-range",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-range.vue")
  }
]
  },
  {
    name: "desktop-projects-partials-filters___fa",
    path: "/fa/desktop/projects/partials/filters",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters.vue"),
    children: [
  {
    name: "desktop-projects-partials-filters-filter-checkbox___fa",
    path: "filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-element___fa",
    path: "filter-element",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-location___fa",
    path: "filter-location",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-radio___fa",
    path: "filter-radio",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-range___fa",
    path: "filter-range",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-range.vue")
  }
]
  },
  {
    name: "desktop-projects-partials-filters___ar",
    path: "/ar/desktop/projects/partials/filters",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters.vue"),
    children: [
  {
    name: "desktop-projects-partials-filters-filter-checkbox___ar",
    path: "filter-checkbox",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-checkbox.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-element___ar",
    path: "filter-element",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-element.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-location___ar",
    path: "filter-location",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-location.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-radio___ar",
    path: "filter-radio",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-radio.vue")
  },
  {
    name: "desktop-projects-partials-filters-filter-range___ar",
    path: "filter-range",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/filters/filter-range.vue")
  }
]
  },
  {
    name: "desktop-projects-partials-header-menu___en",
    path: "/desktop/projects/partials/header-menu",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/header-menu.vue")
  },
  {
    name: "desktop-projects-partials-header-menu___tr",
    path: "/tr/desktop/projects/partials/header-menu",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/header-menu.vue")
  },
  {
    name: "desktop-projects-partials-header-menu___ru",
    path: "/ru/desktop/projects/partials/header-menu",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/header-menu.vue")
  },
  {
    name: "desktop-projects-partials-header-menu___fa",
    path: "/fa/desktop/projects/partials/header-menu",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/header-menu.vue")
  },
  {
    name: "desktop-projects-partials-header-menu___ar",
    path: "/ar/desktop/projects/partials/header-menu",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/header-menu.vue")
  },
  {
    name: "desktop-projects-partials-project-card___en",
    path: "/desktop/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/project-card.vue")
  },
  {
    name: "desktop-projects-partials-project-card___tr",
    path: "/tr/desktop/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/project-card.vue")
  },
  {
    name: "desktop-projects-partials-project-card___ru",
    path: "/ru/desktop/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/project-card.vue")
  },
  {
    name: "desktop-projects-partials-project-card___fa",
    path: "/fa/desktop/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/project-card.vue")
  },
  {
    name: "desktop-projects-partials-project-card___ar",
    path: "/ar/desktop/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/desktop/projects/partials/project-card.vue")
  },
  {
    name: "desktop-projects-show___en",
    path: "/desktop/projects/show",
    component: () => import("/root/apps/web/pages/desktop/projects/show.vue")
  },
  {
    name: "desktop-projects-show___tr",
    path: "/tr/desktop/projects/show",
    component: () => import("/root/apps/web/pages/desktop/projects/show.vue")
  },
  {
    name: "desktop-projects-show___ru",
    path: "/ru/desktop/projects/show",
    component: () => import("/root/apps/web/pages/desktop/projects/show.vue")
  },
  {
    name: "desktop-projects-show___fa",
    path: "/fa/desktop/projects/show",
    component: () => import("/root/apps/web/pages/desktop/projects/show.vue")
  },
  {
    name: "desktop-projects-show___ar",
    path: "/ar/desktop/projects/show",
    component: () => import("/root/apps/web/pages/desktop/projects/show.vue")
  },
  {
    name: "desktop-qr-code___en",
    path: "/desktop/qr-code",
    component: () => import("/root/apps/web/pages/desktop/qr-code/index.vue")
  },
  {
    name: "desktop-qr-code___tr",
    path: "/tr/desktop/qr-code",
    component: () => import("/root/apps/web/pages/desktop/qr-code/index.vue")
  },
  {
    name: "desktop-qr-code___ru",
    path: "/ru/desktop/qr-code",
    component: () => import("/root/apps/web/pages/desktop/qr-code/index.vue")
  },
  {
    name: "desktop-qr-code___fa",
    path: "/fa/desktop/qr-code",
    component: () => import("/root/apps/web/pages/desktop/qr-code/index.vue")
  },
  {
    name: "desktop-qr-code___ar",
    path: "/ar/desktop/qr-code",
    component: () => import("/root/apps/web/pages/desktop/qr-code/index.vue")
  },
  {
    name: "desktop-sitemaps___en",
    path: "/desktop/sitemaps",
    component: () => import("/root/apps/web/pages/desktop/sitemaps/index.vue")
  },
  {
    name: "desktop-sitemaps___tr",
    path: "/tr/desktop/sitemaps",
    component: () => import("/root/apps/web/pages/desktop/sitemaps/index.vue")
  },
  {
    name: "desktop-sitemaps___ru",
    path: "/ru/desktop/sitemaps",
    component: () => import("/root/apps/web/pages/desktop/sitemaps/index.vue")
  },
  {
    name: "desktop-sitemaps___fa",
    path: "/fa/desktop/sitemaps",
    component: () => import("/root/apps/web/pages/desktop/sitemaps/index.vue")
  },
  {
    name: "desktop-sitemaps___ar",
    path: "/ar/desktop/sitemaps",
    component: () => import("/root/apps/web/pages/desktop/sitemaps/index.vue")
  },
  {
    name: "desktop-slug___en",
    path: "/desktop/slug",
    component: () => import("/root/apps/web/pages/desktop/slug/index.vue")
  },
  {
    name: "desktop-slug___tr",
    path: "/tr/desktop/slug",
    component: () => import("/root/apps/web/pages/desktop/slug/index.vue")
  },
  {
    name: "desktop-slug___ru",
    path: "/ru/desktop/slug",
    component: () => import("/root/apps/web/pages/desktop/slug/index.vue")
  },
  {
    name: "desktop-slug___fa",
    path: "/fa/desktop/slug",
    component: () => import("/root/apps/web/pages/desktop/slug/index.vue")
  },
  {
    name: "desktop-slug___ar",
    path: "/ar/desktop/slug",
    component: () => import("/root/apps/web/pages/desktop/slug/index.vue")
  },
  {
    name: "desktop-teammates___en",
    path: "/desktop/teammates",
    component: () => import("/root/apps/web/pages/desktop/teammates/index.vue")
  },
  {
    name: "desktop-teammates___tr",
    path: "/tr/desktop/teammates",
    component: () => import("/root/apps/web/pages/desktop/teammates/index.vue")
  },
  {
    name: "desktop-teammates___ru",
    path: "/ru/desktop/teammates",
    component: () => import("/root/apps/web/pages/desktop/teammates/index.vue")
  },
  {
    name: "desktop-teammates___fa",
    path: "/fa/desktop/teammates",
    component: () => import("/root/apps/web/pages/desktop/teammates/index.vue")
  },
  {
    name: "desktop-teammates___ar",
    path: "/ar/desktop/teammates",
    component: () => import("/root/apps/web/pages/desktop/teammates/index.vue")
  },
  {
    name: "desktop-teammates-show___en",
    path: "/desktop/teammates/show",
    component: () => import("/root/apps/web/pages/desktop/teammates/show.vue")
  },
  {
    name: "desktop-teammates-show___tr",
    path: "/tr/desktop/teammates/show",
    component: () => import("/root/apps/web/pages/desktop/teammates/show.vue")
  },
  {
    name: "desktop-teammates-show___ru",
    path: "/ru/desktop/teammates/show",
    component: () => import("/root/apps/web/pages/desktop/teammates/show.vue")
  },
  {
    name: "desktop-teammates-show___fa",
    path: "/fa/desktop/teammates/show",
    component: () => import("/root/apps/web/pages/desktop/teammates/show.vue")
  },
  {
    name: "desktop-teammates-show___ar",
    path: "/ar/desktop/teammates/show",
    component: () => import("/root/apps/web/pages/desktop/teammates/show.vue")
  },
  {
    name: "mobile-account-customers-form___en",
    path: "/mobile/account/customers/form",
    component: () => import("/root/apps/web/pages/mobile/account/customers/form/index.vue")
  },
  {
    name: "mobile-account-customers-form___tr",
    path: "/tr/mobile/account/customers/form",
    component: () => import("/root/apps/web/pages/mobile/account/customers/form/index.vue")
  },
  {
    name: "mobile-account-customers-form___ru",
    path: "/ru/mobile/account/customers/form",
    component: () => import("/root/apps/web/pages/mobile/account/customers/form/index.vue")
  },
  {
    name: "mobile-account-customers-form___fa",
    path: "/fa/mobile/account/customers/form",
    component: () => import("/root/apps/web/pages/mobile/account/customers/form/index.vue")
  },
  {
    name: "mobile-account-customers-form___ar",
    path: "/ar/mobile/account/customers/form",
    component: () => import("/root/apps/web/pages/mobile/account/customers/form/index.vue")
  },
  {
    name: "mobile-account-customers___en",
    path: "/mobile/account/customers",
    component: () => import("/root/apps/web/pages/mobile/account/customers/index.vue")
  },
  {
    name: "mobile-account-customers___tr",
    path: "/tr/mobile/account/customers",
    component: () => import("/root/apps/web/pages/mobile/account/customers/index.vue")
  },
  {
    name: "mobile-account-customers___ru",
    path: "/ru/mobile/account/customers",
    component: () => import("/root/apps/web/pages/mobile/account/customers/index.vue")
  },
  {
    name: "mobile-account-customers___fa",
    path: "/fa/mobile/account/customers",
    component: () => import("/root/apps/web/pages/mobile/account/customers/index.vue")
  },
  {
    name: "mobile-account-customers___ar",
    path: "/ar/mobile/account/customers",
    component: () => import("/root/apps/web/pages/mobile/account/customers/index.vue")
  },
  {
    name: "mobile-account-customers-partials-filters___en",
    path: "/mobile/account/customers/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/index.vue")
  },
  {
    name: "mobile-account-customers-partials-filters___tr",
    path: "/tr/mobile/account/customers/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/index.vue")
  },
  {
    name: "mobile-account-customers-partials-filters___ru",
    path: "/ru/mobile/account/customers/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/index.vue")
  },
  {
    name: "mobile-account-customers-partials-filters___fa",
    path: "/fa/mobile/account/customers/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/index.vue")
  },
  {
    name: "mobile-account-customers-partials-filters___ar",
    path: "/ar/mobile/account/customers/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/index.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-categories___en",
    path: "/mobile/account/customers/partials/filters/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/categories.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-categories___tr",
    path: "/tr/mobile/account/customers/partials/filters/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/categories.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-categories___ru",
    path: "/ru/mobile/account/customers/partials/filters/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/categories.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-categories___fa",
    path: "/fa/mobile/account/customers/partials/filters/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/categories.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-categories___ar",
    path: "/ar/mobile/account/customers/partials/filters/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/categories.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-sources___en",
    path: "/mobile/account/customers/partials/filters/partials/sources",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/sources.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-sources___tr",
    path: "/tr/mobile/account/customers/partials/filters/partials/sources",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/sources.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-sources___ru",
    path: "/ru/mobile/account/customers/partials/filters/partials/sources",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/sources.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-sources___fa",
    path: "/fa/mobile/account/customers/partials/filters/partials/sources",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/sources.vue")
  },
  {
    name: "mobile-account-customers-partials-filters-partials-sources___ar",
    path: "/ar/mobile/account/customers/partials/filters/partials/sources",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/filters/partials/sources.vue")
  },
  {
    name: "mobile-account-customers-partials-mobile-account-customers-card___en",
    path: "/mobile/account/customers/partials/mobile-account-customers-card",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/mobile-account-customers-card.vue")
  },
  {
    name: "mobile-account-customers-partials-mobile-account-customers-card___tr",
    path: "/tr/mobile/account/customers/partials/mobile-account-customers-card",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/mobile-account-customers-card.vue")
  },
  {
    name: "mobile-account-customers-partials-mobile-account-customers-card___ru",
    path: "/ru/mobile/account/customers/partials/mobile-account-customers-card",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/mobile-account-customers-card.vue")
  },
  {
    name: "mobile-account-customers-partials-mobile-account-customers-card___fa",
    path: "/fa/mobile/account/customers/partials/mobile-account-customers-card",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/mobile-account-customers-card.vue")
  },
  {
    name: "mobile-account-customers-partials-mobile-account-customers-card___ar",
    path: "/ar/mobile/account/customers/partials/mobile-account-customers-card",
    component: () => import("/root/apps/web/pages/mobile/account/customers/partials/mobile-account-customers-card.vue")
  },
  {
    name: "mobile-account-customers-show___en",
    path: "/mobile/account/customers/show",
    component: () => import("/root/apps/web/pages/mobile/account/customers/show/index.vue")
  },
  {
    name: "mobile-account-customers-show___tr",
    path: "/tr/mobile/account/customers/show",
    component: () => import("/root/apps/web/pages/mobile/account/customers/show/index.vue")
  },
  {
    name: "mobile-account-customers-show___ru",
    path: "/ru/mobile/account/customers/show",
    component: () => import("/root/apps/web/pages/mobile/account/customers/show/index.vue")
  },
  {
    name: "mobile-account-customers-show___fa",
    path: "/fa/mobile/account/customers/show",
    component: () => import("/root/apps/web/pages/mobile/account/customers/show/index.vue")
  },
  {
    name: "mobile-account-customers-show___ar",
    path: "/ar/mobile/account/customers/show",
    component: () => import("/root/apps/web/pages/mobile/account/customers/show/index.vue")
  },
  {
    name: "mobile-account-forget-password___en",
    path: "/mobile/account/forget-password",
    component: () => import("/root/apps/web/pages/mobile/account/forget-password/index.vue")
  },
  {
    name: "mobile-account-forget-password___tr",
    path: "/tr/mobile/account/forget-password",
    component: () => import("/root/apps/web/pages/mobile/account/forget-password/index.vue")
  },
  {
    name: "mobile-account-forget-password___ru",
    path: "/ru/mobile/account/forget-password",
    component: () => import("/root/apps/web/pages/mobile/account/forget-password/index.vue")
  },
  {
    name: "mobile-account-forget-password___fa",
    path: "/fa/mobile/account/forget-password",
    component: () => import("/root/apps/web/pages/mobile/account/forget-password/index.vue")
  },
  {
    name: "mobile-account-forget-password___ar",
    path: "/ar/mobile/account/forget-password",
    component: () => import("/root/apps/web/pages/mobile/account/forget-password/index.vue")
  },
  {
    name: "mobile-account-listings-add___en",
    path: "/mobile/account/listings/add",
    component: () => import("/root/apps/web/pages/mobile/account/listings/add.vue")
  },
  {
    name: "mobile-account-listings-add___tr",
    path: "/tr/mobile/account/listings/add",
    component: () => import("/root/apps/web/pages/mobile/account/listings/add.vue")
  },
  {
    name: "mobile-account-listings-add___ru",
    path: "/ru/mobile/account/listings/add",
    component: () => import("/root/apps/web/pages/mobile/account/listings/add.vue")
  },
  {
    name: "mobile-account-listings-add___fa",
    path: "/fa/mobile/account/listings/add",
    component: () => import("/root/apps/web/pages/mobile/account/listings/add.vue")
  },
  {
    name: "mobile-account-listings-add___ar",
    path: "/ar/mobile/account/listings/add",
    component: () => import("/root/apps/web/pages/mobile/account/listings/add.vue")
  },
  {
    name: "mobile-account-listings___en",
    path: "/mobile/account/listings",
    component: () => import("/root/apps/web/pages/mobile/account/listings/index.vue")
  },
  {
    name: "mobile-account-listings___tr",
    path: "/tr/mobile/account/listings",
    component: () => import("/root/apps/web/pages/mobile/account/listings/index.vue")
  },
  {
    name: "mobile-account-listings___ru",
    path: "/ru/mobile/account/listings",
    component: () => import("/root/apps/web/pages/mobile/account/listings/index.vue")
  },
  {
    name: "mobile-account-listings___fa",
    path: "/fa/mobile/account/listings",
    component: () => import("/root/apps/web/pages/mobile/account/listings/index.vue")
  },
  {
    name: "mobile-account-listings___ar",
    path: "/ar/mobile/account/listings",
    component: () => import("/root/apps/web/pages/mobile/account/listings/index.vue")
  },
  {
    name: "mobile-account-listings-last-seen___en",
    path: "/mobile/account/listings/last-seen",
    component: () => import("/root/apps/web/pages/mobile/account/listings/last-seen.vue")
  },
  {
    name: "mobile-account-listings-last-seen___tr",
    path: "/tr/mobile/account/listings/last-seen",
    component: () => import("/root/apps/web/pages/mobile/account/listings/last-seen.vue")
  },
  {
    name: "mobile-account-listings-last-seen___ru",
    path: "/ru/mobile/account/listings/last-seen",
    component: () => import("/root/apps/web/pages/mobile/account/listings/last-seen.vue")
  },
  {
    name: "mobile-account-listings-last-seen___fa",
    path: "/fa/mobile/account/listings/last-seen",
    component: () => import("/root/apps/web/pages/mobile/account/listings/last-seen.vue")
  },
  {
    name: "mobile-account-listings-last-seen___ar",
    path: "/ar/mobile/account/listings/last-seen",
    component: () => import("/root/apps/web/pages/mobile/account/listings/last-seen.vue")
  },
  {
    name: "mobile-account-listings-partials-add-form___en",
    path: "/mobile/account/listings/partials/add-form",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/add-form.vue")
  },
  {
    name: "mobile-account-listings-partials-add-form___tr",
    path: "/tr/mobile/account/listings/partials/add-form",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/add-form.vue")
  },
  {
    name: "mobile-account-listings-partials-add-form___ru",
    path: "/ru/mobile/account/listings/partials/add-form",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/add-form.vue")
  },
  {
    name: "mobile-account-listings-partials-add-form___fa",
    path: "/fa/mobile/account/listings/partials/add-form",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/add-form.vue")
  },
  {
    name: "mobile-account-listings-partials-add-form___ar",
    path: "/ar/mobile/account/listings/partials/add-form",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/add-form.vue")
  },
  {
    name: "mobile-account-listings-partials-categories___en",
    path: "/mobile/account/listings/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/categories.vue")
  },
  {
    name: "mobile-account-listings-partials-categories___tr",
    path: "/tr/mobile/account/listings/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/categories.vue")
  },
  {
    name: "mobile-account-listings-partials-categories___ru",
    path: "/ru/mobile/account/listings/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/categories.vue")
  },
  {
    name: "mobile-account-listings-partials-categories___fa",
    path: "/fa/mobile/account/listings/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/categories.vue")
  },
  {
    name: "mobile-account-listings-partials-categories___ar",
    path: "/ar/mobile/account/listings/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/categories.vue")
  },
  {
    name: "mobile-account-listings-partials-favorite-card___en",
    path: "/mobile/account/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/favorite-card.vue")
  },
  {
    name: "mobile-account-listings-partials-favorite-card___tr",
    path: "/tr/mobile/account/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/favorite-card.vue")
  },
  {
    name: "mobile-account-listings-partials-favorite-card___ru",
    path: "/ru/mobile/account/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/favorite-card.vue")
  },
  {
    name: "mobile-account-listings-partials-favorite-card___fa",
    path: "/fa/mobile/account/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/favorite-card.vue")
  },
  {
    name: "mobile-account-listings-partials-favorite-card___ar",
    path: "/ar/mobile/account/listings/partials/favorite-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/favorite-card.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-card___en",
    path: "/mobile/account/listings/partials/listing-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-card.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-card___tr",
    path: "/tr/mobile/account/listings/partials/listing-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-card.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-card___ru",
    path: "/ru/mobile/account/listings/partials/listing-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-card.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-card___fa",
    path: "/fa/mobile/account/listings/partials/listing-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-card.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-card___ar",
    path: "/ar/mobile/account/listings/partials/listing-card",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-card.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-contact___en",
    path: "/mobile/account/listings/partials/listing-contact",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-contact.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-contact___tr",
    path: "/tr/mobile/account/listings/partials/listing-contact",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-contact.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-contact___ru",
    path: "/ru/mobile/account/listings/partials/listing-contact",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-contact.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-contact___fa",
    path: "/fa/mobile/account/listings/partials/listing-contact",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-contact.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-contact___ar",
    path: "/ar/mobile/account/listings/partials/listing-contact",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-contact.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-lists-favorite___en",
    path: "/mobile/account/listings/partials/listing-lists-favorite",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-lists-favorite.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-lists-favorite___tr",
    path: "/tr/mobile/account/listings/partials/listing-lists-favorite",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-lists-favorite.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-lists-favorite___ru",
    path: "/ru/mobile/account/listings/partials/listing-lists-favorite",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-lists-favorite.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-lists-favorite___fa",
    path: "/fa/mobile/account/listings/partials/listing-lists-favorite",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-lists-favorite.vue")
  },
  {
    name: "mobile-account-listings-partials-listing-lists-favorite___ar",
    path: "/ar/mobile/account/listings/partials/listing-lists-favorite",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/listing-lists-favorite.vue")
  },
  {
    name: "mobile-account-listings-partials-sort-button___en",
    path: "/mobile/account/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/sort-button.vue")
  },
  {
    name: "mobile-account-listings-partials-sort-button___tr",
    path: "/tr/mobile/account/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/sort-button.vue")
  },
  {
    name: "mobile-account-listings-partials-sort-button___ru",
    path: "/ru/mobile/account/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/sort-button.vue")
  },
  {
    name: "mobile-account-listings-partials-sort-button___fa",
    path: "/fa/mobile/account/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/sort-button.vue")
  },
  {
    name: "mobile-account-listings-partials-sort-button___ar",
    path: "/ar/mobile/account/listings/partials/sort-button",
    component: () => import("/root/apps/web/pages/mobile/account/listings/partials/sort-button.vue")
  },
  {
    name: "mobile-account-listings-preview___en",
    path: "/mobile/account/listings/preview",
    component: () => import("/root/apps/web/pages/mobile/account/listings/preview.vue")
  },
  {
    name: "mobile-account-listings-preview___tr",
    path: "/tr/mobile/account/listings/preview",
    component: () => import("/root/apps/web/pages/mobile/account/listings/preview.vue")
  },
  {
    name: "mobile-account-listings-preview___ru",
    path: "/ru/mobile/account/listings/preview",
    component: () => import("/root/apps/web/pages/mobile/account/listings/preview.vue")
  },
  {
    name: "mobile-account-listings-preview___fa",
    path: "/fa/mobile/account/listings/preview",
    component: () => import("/root/apps/web/pages/mobile/account/listings/preview.vue")
  },
  {
    name: "mobile-account-listings-preview___ar",
    path: "/ar/mobile/account/listings/preview",
    component: () => import("/root/apps/web/pages/mobile/account/listings/preview.vue")
  },
  {
    name: "mobile-account-listings-show___en",
    path: "/mobile/account/listings/show",
    component: () => import("/root/apps/web/pages/mobile/account/listings/show.vue")
  },
  {
    name: "mobile-account-listings-show___tr",
    path: "/tr/mobile/account/listings/show",
    component: () => import("/root/apps/web/pages/mobile/account/listings/show.vue")
  },
  {
    name: "mobile-account-listings-show___ru",
    path: "/ru/mobile/account/listings/show",
    component: () => import("/root/apps/web/pages/mobile/account/listings/show.vue")
  },
  {
    name: "mobile-account-listings-show___fa",
    path: "/fa/mobile/account/listings/show",
    component: () => import("/root/apps/web/pages/mobile/account/listings/show.vue")
  },
  {
    name: "mobile-account-listings-show___ar",
    path: "/ar/mobile/account/listings/show",
    component: () => import("/root/apps/web/pages/mobile/account/listings/show.vue")
  },
  {
    name: "mobile-account-login___en",
    path: "/mobile/account/login",
    component: () => import("/root/apps/web/pages/mobile/account/login/index.vue")
  },
  {
    name: "mobile-account-login___tr",
    path: "/tr/mobile/account/login",
    component: () => import("/root/apps/web/pages/mobile/account/login/index.vue")
  },
  {
    name: "mobile-account-login___ru",
    path: "/ru/mobile/account/login",
    component: () => import("/root/apps/web/pages/mobile/account/login/index.vue")
  },
  {
    name: "mobile-account-login___fa",
    path: "/fa/mobile/account/login",
    component: () => import("/root/apps/web/pages/mobile/account/login/index.vue")
  },
  {
    name: "mobile-account-login___ar",
    path: "/ar/mobile/account/login",
    component: () => import("/root/apps/web/pages/mobile/account/login/index.vue")
  },
  {
    name: "mobile-account-notifications___en",
    path: "/mobile/account/notifications",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/index.vue")
  },
  {
    name: "mobile-account-notifications___tr",
    path: "/tr/mobile/account/notifications",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/index.vue")
  },
  {
    name: "mobile-account-notifications___ru",
    path: "/ru/mobile/account/notifications",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/index.vue")
  },
  {
    name: "mobile-account-notifications___fa",
    path: "/fa/mobile/account/notifications",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/index.vue")
  },
  {
    name: "mobile-account-notifications___ar",
    path: "/ar/mobile/account/notifications",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/index.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-card___en",
    path: "/mobile/account/notifications/partials/mobile-notification-card",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-card.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-card___tr",
    path: "/tr/mobile/account/notifications/partials/mobile-notification-card",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-card.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-card___ru",
    path: "/ru/mobile/account/notifications/partials/mobile-notification-card",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-card.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-card___fa",
    path: "/fa/mobile/account/notifications/partials/mobile-notification-card",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-card.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-card___ar",
    path: "/ar/mobile/account/notifications/partials/mobile-notification-card",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-card.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-mark-all-read___en",
    path: "/mobile/account/notifications/partials/mobile-notification-mark-all-read",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-mark-all-read.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-mark-all-read___tr",
    path: "/tr/mobile/account/notifications/partials/mobile-notification-mark-all-read",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-mark-all-read.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-mark-all-read___ru",
    path: "/ru/mobile/account/notifications/partials/mobile-notification-mark-all-read",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-mark-all-read.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-mark-all-read___fa",
    path: "/fa/mobile/account/notifications/partials/mobile-notification-mark-all-read",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-mark-all-read.vue")
  },
  {
    name: "mobile-account-notifications-partials-mobile-notification-mark-all-read___ar",
    path: "/ar/mobile/account/notifications/partials/mobile-notification-mark-all-read",
    component: () => import("/root/apps/web/pages/mobile/account/notifications/partials/mobile-notification-mark-all-read.vue")
  },
  {
    name: "mobile-account-offers-form-cities-input___en",
    path: "/mobile/account/offers/form/cities-input",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/cities-input.vue")
  },
  {
    name: "mobile-account-offers-form-cities-input___tr",
    path: "/tr/mobile/account/offers/form/cities-input",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/cities-input.vue")
  },
  {
    name: "mobile-account-offers-form-cities-input___ru",
    path: "/ru/mobile/account/offers/form/cities-input",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/cities-input.vue")
  },
  {
    name: "mobile-account-offers-form-cities-input___fa",
    path: "/fa/mobile/account/offers/form/cities-input",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/cities-input.vue")
  },
  {
    name: "mobile-account-offers-form-cities-input___ar",
    path: "/ar/mobile/account/offers/form/cities-input",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/cities-input.vue")
  },
  {
    name: "mobile-account-offers-form___en",
    path: "/mobile/account/offers/form",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/index.vue")
  },
  {
    name: "mobile-account-offers-form___tr",
    path: "/tr/mobile/account/offers/form",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/index.vue")
  },
  {
    name: "mobile-account-offers-form___ru",
    path: "/ru/mobile/account/offers/form",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/index.vue")
  },
  {
    name: "mobile-account-offers-form___fa",
    path: "/fa/mobile/account/offers/form",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/index.vue")
  },
  {
    name: "mobile-account-offers-form___ar",
    path: "/ar/mobile/account/offers/form",
    component: () => import("/root/apps/web/pages/mobile/account/offers/form/index.vue")
  },
  {
    name: "mobile-account-offers___en",
    path: "/mobile/account/offers",
    component: () => import("/root/apps/web/pages/mobile/account/offers/index.vue")
  },
  {
    name: "mobile-account-offers___tr",
    path: "/tr/mobile/account/offers",
    component: () => import("/root/apps/web/pages/mobile/account/offers/index.vue")
  },
  {
    name: "mobile-account-offers___ru",
    path: "/ru/mobile/account/offers",
    component: () => import("/root/apps/web/pages/mobile/account/offers/index.vue")
  },
  {
    name: "mobile-account-offers___fa",
    path: "/fa/mobile/account/offers",
    component: () => import("/root/apps/web/pages/mobile/account/offers/index.vue")
  },
  {
    name: "mobile-account-offers___ar",
    path: "/ar/mobile/account/offers",
    component: () => import("/root/apps/web/pages/mobile/account/offers/index.vue")
  },
  {
    name: "mobile-account-offers-list___en",
    path: "/mobile/account/offers/list",
    component: () => import("/root/apps/web/pages/mobile/account/offers/list.vue")
  },
  {
    name: "mobile-account-offers-list___tr",
    path: "/tr/mobile/account/offers/list",
    component: () => import("/root/apps/web/pages/mobile/account/offers/list.vue")
  },
  {
    name: "mobile-account-offers-list___ru",
    path: "/ru/mobile/account/offers/list",
    component: () => import("/root/apps/web/pages/mobile/account/offers/list.vue")
  },
  {
    name: "mobile-account-offers-list___fa",
    path: "/fa/mobile/account/offers/list",
    component: () => import("/root/apps/web/pages/mobile/account/offers/list.vue")
  },
  {
    name: "mobile-account-offers-list___ar",
    path: "/ar/mobile/account/offers/list",
    component: () => import("/root/apps/web/pages/mobile/account/offers/list.vue")
  },
  {
    name: "mobile-account-offers-partial-card___en",
    path: "/mobile/account/offers/partial/card",
    component: () => import("/root/apps/web/pages/mobile/account/offers/partial/card.vue")
  },
  {
    name: "mobile-account-offers-partial-card___tr",
    path: "/tr/mobile/account/offers/partial/card",
    component: () => import("/root/apps/web/pages/mobile/account/offers/partial/card.vue")
  },
  {
    name: "mobile-account-offers-partial-card___ru",
    path: "/ru/mobile/account/offers/partial/card",
    component: () => import("/root/apps/web/pages/mobile/account/offers/partial/card.vue")
  },
  {
    name: "mobile-account-offers-partial-card___fa",
    path: "/fa/mobile/account/offers/partial/card",
    component: () => import("/root/apps/web/pages/mobile/account/offers/partial/card.vue")
  },
  {
    name: "mobile-account-offers-partial-card___ar",
    path: "/ar/mobile/account/offers/partial/card",
    component: () => import("/root/apps/web/pages/mobile/account/offers/partial/card.vue")
  },
  {
    name: "mobile-account-profile-change-email___en",
    path: "/mobile/account/profile/change-email",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-email.vue")
  },
  {
    name: "mobile-account-profile-change-email___tr",
    path: "/tr/mobile/account/profile/change-email",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-email.vue")
  },
  {
    name: "mobile-account-profile-change-email___ru",
    path: "/ru/mobile/account/profile/change-email",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-email.vue")
  },
  {
    name: "mobile-account-profile-change-email___fa",
    path: "/fa/mobile/account/profile/change-email",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-email.vue")
  },
  {
    name: "mobile-account-profile-change-email___ar",
    path: "/ar/mobile/account/profile/change-email",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-email.vue")
  },
  {
    name: "mobile-account-profile-change-name___en",
    path: "/mobile/account/profile/change-name",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-name.vue")
  },
  {
    name: "mobile-account-profile-change-name___tr",
    path: "/tr/mobile/account/profile/change-name",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-name.vue")
  },
  {
    name: "mobile-account-profile-change-name___ru",
    path: "/ru/mobile/account/profile/change-name",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-name.vue")
  },
  {
    name: "mobile-account-profile-change-name___fa",
    path: "/fa/mobile/account/profile/change-name",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-name.vue")
  },
  {
    name: "mobile-account-profile-change-name___ar",
    path: "/ar/mobile/account/profile/change-name",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-name.vue")
  },
  {
    name: "mobile-account-profile-change-password___en",
    path: "/mobile/account/profile/change-password",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-password.vue")
  },
  {
    name: "mobile-account-profile-change-password___tr",
    path: "/tr/mobile/account/profile/change-password",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-password.vue")
  },
  {
    name: "mobile-account-profile-change-password___ru",
    path: "/ru/mobile/account/profile/change-password",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-password.vue")
  },
  {
    name: "mobile-account-profile-change-password___fa",
    path: "/fa/mobile/account/profile/change-password",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-password.vue")
  },
  {
    name: "mobile-account-profile-change-password___ar",
    path: "/ar/mobile/account/profile/change-password",
    component: () => import("/root/apps/web/pages/mobile/account/profile/change-password.vue")
  },
  {
    name: "mobile-account-profile___en",
    path: "/mobile/account/profile",
    component: () => import("/root/apps/web/pages/mobile/account/profile/index.vue")
  },
  {
    name: "mobile-account-profile___tr",
    path: "/tr/mobile/account/profile",
    component: () => import("/root/apps/web/pages/mobile/account/profile/index.vue")
  },
  {
    name: "mobile-account-profile___ru",
    path: "/ru/mobile/account/profile",
    component: () => import("/root/apps/web/pages/mobile/account/profile/index.vue")
  },
  {
    name: "mobile-account-profile___fa",
    path: "/fa/mobile/account/profile",
    component: () => import("/root/apps/web/pages/mobile/account/profile/index.vue")
  },
  {
    name: "mobile-account-profile___ar",
    path: "/ar/mobile/account/profile",
    component: () => import("/root/apps/web/pages/mobile/account/profile/index.vue")
  },
  {
    name: "mobile-account-profile-mobile-phones___en",
    path: "/mobile/account/profile/mobile-phones",
    component: () => import("/root/apps/web/pages/mobile/account/profile/mobile-phones.vue")
  },
  {
    name: "mobile-account-profile-mobile-phones___tr",
    path: "/tr/mobile/account/profile/mobile-phones",
    component: () => import("/root/apps/web/pages/mobile/account/profile/mobile-phones.vue")
  },
  {
    name: "mobile-account-profile-mobile-phones___ru",
    path: "/ru/mobile/account/profile/mobile-phones",
    component: () => import("/root/apps/web/pages/mobile/account/profile/mobile-phones.vue")
  },
  {
    name: "mobile-account-profile-mobile-phones___fa",
    path: "/fa/mobile/account/profile/mobile-phones",
    component: () => import("/root/apps/web/pages/mobile/account/profile/mobile-phones.vue")
  },
  {
    name: "mobile-account-profile-mobile-phones___ar",
    path: "/ar/mobile/account/profile/mobile-phones",
    component: () => import("/root/apps/web/pages/mobile/account/profile/mobile-phones.vue")
  },
  {
    name: "mobile-account-profile-partials-add-mobile-phone___en",
    path: "/mobile/account/profile/partials/add-mobile-phone",
    component: () => import("/root/apps/web/pages/mobile/account/profile/partials/add-mobile-phone.vue")
  },
  {
    name: "mobile-account-profile-partials-add-mobile-phone___tr",
    path: "/tr/mobile/account/profile/partials/add-mobile-phone",
    component: () => import("/root/apps/web/pages/mobile/account/profile/partials/add-mobile-phone.vue")
  },
  {
    name: "mobile-account-profile-partials-add-mobile-phone___ru",
    path: "/ru/mobile/account/profile/partials/add-mobile-phone",
    component: () => import("/root/apps/web/pages/mobile/account/profile/partials/add-mobile-phone.vue")
  },
  {
    name: "mobile-account-profile-partials-add-mobile-phone___fa",
    path: "/fa/mobile/account/profile/partials/add-mobile-phone",
    component: () => import("/root/apps/web/pages/mobile/account/profile/partials/add-mobile-phone.vue")
  },
  {
    name: "mobile-account-profile-partials-add-mobile-phone___ar",
    path: "/ar/mobile/account/profile/partials/add-mobile-phone",
    component: () => import("/root/apps/web/pages/mobile/account/profile/partials/add-mobile-phone.vue")
  },
  {
    name: "mobile-account-register___en",
    path: "/mobile/account/register",
    component: () => import("/root/apps/web/pages/mobile/account/register/index.vue")
  },
  {
    name: "mobile-account-register___tr",
    path: "/tr/mobile/account/register",
    component: () => import("/root/apps/web/pages/mobile/account/register/index.vue")
  },
  {
    name: "mobile-account-register___ru",
    path: "/ru/mobile/account/register",
    component: () => import("/root/apps/web/pages/mobile/account/register/index.vue")
  },
  {
    name: "mobile-account-register___fa",
    path: "/fa/mobile/account/register",
    component: () => import("/root/apps/web/pages/mobile/account/register/index.vue")
  },
  {
    name: "mobile-account-register___ar",
    path: "/ar/mobile/account/register",
    component: () => import("/root/apps/web/pages/mobile/account/register/index.vue")
  },
  {
    name: "mobile-account-verify-account___en",
    path: "/mobile/account/verify-account",
    component: () => import("/root/apps/web/pages/mobile/account/verify-account/index.vue")
  },
  {
    name: "mobile-account-verify-account___tr",
    path: "/tr/mobile/account/verify-account",
    component: () => import("/root/apps/web/pages/mobile/account/verify-account/index.vue")
  },
  {
    name: "mobile-account-verify-account___ru",
    path: "/ru/mobile/account/verify-account",
    component: () => import("/root/apps/web/pages/mobile/account/verify-account/index.vue")
  },
  {
    name: "mobile-account-verify-account___fa",
    path: "/fa/mobile/account/verify-account",
    component: () => import("/root/apps/web/pages/mobile/account/verify-account/index.vue")
  },
  {
    name: "mobile-account-verify-account___ar",
    path: "/ar/mobile/account/verify-account",
    component: () => import("/root/apps/web/pages/mobile/account/verify-account/index.vue")
  },
  {
    name: "mobile-blogs___en",
    path: "/mobile/blogs",
    component: () => import("/root/apps/web/pages/mobile/blogs/index.vue")
  },
  {
    name: "mobile-blogs___tr",
    path: "/tr/mobile/blogs",
    component: () => import("/root/apps/web/pages/mobile/blogs/index.vue")
  },
  {
    name: "mobile-blogs___ru",
    path: "/ru/mobile/blogs",
    component: () => import("/root/apps/web/pages/mobile/blogs/index.vue")
  },
  {
    name: "mobile-blogs___fa",
    path: "/fa/mobile/blogs",
    component: () => import("/root/apps/web/pages/mobile/blogs/index.vue")
  },
  {
    name: "mobile-blogs___ar",
    path: "/ar/mobile/blogs",
    component: () => import("/root/apps/web/pages/mobile/blogs/index.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-card___en",
    path: "/mobile/blogs/partials/mobile-blog-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-card___tr",
    path: "/tr/mobile/blogs/partials/mobile-blog-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-card___ru",
    path: "/ru/mobile/blogs/partials/mobile-blog-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-card___fa",
    path: "/fa/mobile/blogs/partials/mobile-blog-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-card___ar",
    path: "/ar/mobile/blogs/partials/mobile-blog-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-card___en",
    path: "/mobile/blogs/partials/mobile-blog-header-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-card___tr",
    path: "/tr/mobile/blogs/partials/mobile-blog-header-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-card___ru",
    path: "/ru/mobile/blogs/partials/mobile-blog-header-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-card___fa",
    path: "/fa/mobile/blogs/partials/mobile-blog-header-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-card___ar",
    path: "/ar/mobile/blogs/partials/mobile-blog-header-card",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-card.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-menus___en",
    path: "/mobile/blogs/partials/mobile-blog-header-menus",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-menus.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-menus___tr",
    path: "/tr/mobile/blogs/partials/mobile-blog-header-menus",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-menus.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-menus___ru",
    path: "/ru/mobile/blogs/partials/mobile-blog-header-menus",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-menus.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-menus___fa",
    path: "/fa/mobile/blogs/partials/mobile-blog-header-menus",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-menus.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-menus___ar",
    path: "/ar/mobile/blogs/partials/mobile-blog-header-menus",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-menus.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-tabs___en",
    path: "/mobile/blogs/partials/mobile-blog-header-tabs",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-tabs.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-tabs___tr",
    path: "/tr/mobile/blogs/partials/mobile-blog-header-tabs",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-tabs.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-tabs___ru",
    path: "/ru/mobile/blogs/partials/mobile-blog-header-tabs",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-tabs.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-tabs___fa",
    path: "/fa/mobile/blogs/partials/mobile-blog-header-tabs",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-tabs.vue")
  },
  {
    name: "mobile-blogs-partials-mobile-blog-header-tabs___ar",
    path: "/ar/mobile/blogs/partials/mobile-blog-header-tabs",
    component: () => import("/root/apps/web/pages/mobile/blogs/partials/mobile-blog-header-tabs.vue")
  },
  {
    name: "mobile-blogs-show___en",
    path: "/mobile/blogs/show",
    component: () => import("/root/apps/web/pages/mobile/blogs/show.vue")
  },
  {
    name: "mobile-blogs-show___tr",
    path: "/tr/mobile/blogs/show",
    component: () => import("/root/apps/web/pages/mobile/blogs/show.vue")
  },
  {
    name: "mobile-blogs-show___ru",
    path: "/ru/mobile/blogs/show",
    component: () => import("/root/apps/web/pages/mobile/blogs/show.vue")
  },
  {
    name: "mobile-blogs-show___fa",
    path: "/fa/mobile/blogs/show",
    component: () => import("/root/apps/web/pages/mobile/blogs/show.vue")
  },
  {
    name: "mobile-blogs-show___ar",
    path: "/ar/mobile/blogs/show",
    component: () => import("/root/apps/web/pages/mobile/blogs/show.vue")
  },
  {
    name: "mobile-contact___en",
    path: "/mobile/contact",
    component: () => import("/root/apps/web/pages/mobile/contact/index.vue")
  },
  {
    name: "mobile-contact___tr",
    path: "/tr/mobile/contact",
    component: () => import("/root/apps/web/pages/mobile/contact/index.vue")
  },
  {
    name: "mobile-contact___ru",
    path: "/ru/mobile/contact",
    component: () => import("/root/apps/web/pages/mobile/contact/index.vue")
  },
  {
    name: "mobile-contact___fa",
    path: "/fa/mobile/contact",
    component: () => import("/root/apps/web/pages/mobile/contact/index.vue")
  },
  {
    name: "mobile-contact___ar",
    path: "/ar/mobile/contact",
    component: () => import("/root/apps/web/pages/mobile/contact/index.vue")
  },
  {
    name: "mobile-developers___en",
    path: "/mobile/developers",
    component: () => import("/root/apps/web/pages/mobile/developers/index.vue")
  },
  {
    name: "mobile-developers___tr",
    path: "/tr/mobile/developers",
    component: () => import("/root/apps/web/pages/mobile/developers/index.vue")
  },
  {
    name: "mobile-developers___ru",
    path: "/ru/mobile/developers",
    component: () => import("/root/apps/web/pages/mobile/developers/index.vue")
  },
  {
    name: "mobile-developers___fa",
    path: "/fa/mobile/developers",
    component: () => import("/root/apps/web/pages/mobile/developers/index.vue")
  },
  {
    name: "mobile-developers___ar",
    path: "/ar/mobile/developers",
    component: () => import("/root/apps/web/pages/mobile/developers/index.vue")
  },
  {
    name: "mobile-developers-show___en",
    path: "/mobile/developers/show",
    component: () => import("/root/apps/web/pages/mobile/developers/show.vue")
  },
  {
    name: "mobile-developers-show___tr",
    path: "/tr/mobile/developers/show",
    component: () => import("/root/apps/web/pages/mobile/developers/show.vue")
  },
  {
    name: "mobile-developers-show___ru",
    path: "/ru/mobile/developers/show",
    component: () => import("/root/apps/web/pages/mobile/developers/show.vue")
  },
  {
    name: "mobile-developers-show___fa",
    path: "/fa/mobile/developers/show",
    component: () => import("/root/apps/web/pages/mobile/developers/show.vue")
  },
  {
    name: "mobile-developers-show___ar",
    path: "/ar/mobile/developers/show",
    component: () => import("/root/apps/web/pages/mobile/developers/show.vue")
  },
  {
    name: "mobile-guides-partials-country___en",
    path: "/mobile/guides/partials/country",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/country.vue")
  },
  {
    name: "mobile-guides-partials-country___tr",
    path: "/tr/mobile/guides/partials/country",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/country.vue")
  },
  {
    name: "mobile-guides-partials-country___ru",
    path: "/ru/mobile/guides/partials/country",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/country.vue")
  },
  {
    name: "mobile-guides-partials-country___fa",
    path: "/fa/mobile/guides/partials/country",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/country.vue")
  },
  {
    name: "mobile-guides-partials-country___ar",
    path: "/ar/mobile/guides/partials/country",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/country.vue")
  },
  {
    name: "mobile-guides-partials-details___en",
    path: "/mobile/guides/partials/details",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/details.vue")
  },
  {
    name: "mobile-guides-partials-details___tr",
    path: "/tr/mobile/guides/partials/details",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/details.vue")
  },
  {
    name: "mobile-guides-partials-details___ru",
    path: "/ru/mobile/guides/partials/details",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/details.vue")
  },
  {
    name: "mobile-guides-partials-details___fa",
    path: "/fa/mobile/guides/partials/details",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/details.vue")
  },
  {
    name: "mobile-guides-partials-details___ar",
    path: "/ar/mobile/guides/partials/details",
    component: () => import("/root/apps/web/pages/mobile/guides/partials/details.vue")
  },
  {
    name: "mobile-guides-show___en",
    path: "/mobile/guides/show",
    component: () => import("/root/apps/web/pages/mobile/guides/show.vue")
  },
  {
    name: "mobile-guides-show___tr",
    path: "/tr/mobile/guides/show",
    component: () => import("/root/apps/web/pages/mobile/guides/show.vue")
  },
  {
    name: "mobile-guides-show___ru",
    path: "/ru/mobile/guides/show",
    component: () => import("/root/apps/web/pages/mobile/guides/show.vue")
  },
  {
    name: "mobile-guides-show___fa",
    path: "/fa/mobile/guides/show",
    component: () => import("/root/apps/web/pages/mobile/guides/show.vue")
  },
  {
    name: "mobile-guides-show___ar",
    path: "/ar/mobile/guides/show",
    component: () => import("/root/apps/web/pages/mobile/guides/show.vue")
  },
  {
    name: "mobile-home___en",
    path: "/mobile/home",
    component: () => import("/root/apps/web/pages/mobile/home/index.vue")
  },
  {
    name: "mobile-home___tr",
    path: "/tr/mobile/home",
    component: () => import("/root/apps/web/pages/mobile/home/index.vue")
  },
  {
    name: "mobile-home___ru",
    path: "/ru/mobile/home",
    component: () => import("/root/apps/web/pages/mobile/home/index.vue")
  },
  {
    name: "mobile-home___fa",
    path: "/fa/mobile/home",
    component: () => import("/root/apps/web/pages/mobile/home/index.vue")
  },
  {
    name: "mobile-home___ar",
    path: "/ar/mobile/home",
    component: () => import("/root/apps/web/pages/mobile/home/index.vue")
  },
  {
    name: "mobile-home-partials-categories___en",
    path: "/mobile/home/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/home/partials/categories.vue")
  },
  {
    name: "mobile-home-partials-categories___tr",
    path: "/tr/mobile/home/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/home/partials/categories.vue")
  },
  {
    name: "mobile-home-partials-categories___ru",
    path: "/ru/mobile/home/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/home/partials/categories.vue")
  },
  {
    name: "mobile-home-partials-categories___fa",
    path: "/fa/mobile/home/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/home/partials/categories.vue")
  },
  {
    name: "mobile-home-partials-categories___ar",
    path: "/ar/mobile/home/partials/categories",
    component: () => import("/root/apps/web/pages/mobile/home/partials/categories.vue")
  },
  {
    name: "mobile-home-partials-hero___en",
    path: "/mobile/home/partials/hero",
    component: () => import("/root/apps/web/pages/mobile/home/partials/hero.vue")
  },
  {
    name: "mobile-home-partials-hero___tr",
    path: "/tr/mobile/home/partials/hero",
    component: () => import("/root/apps/web/pages/mobile/home/partials/hero.vue")
  },
  {
    name: "mobile-home-partials-hero___ru",
    path: "/ru/mobile/home/partials/hero",
    component: () => import("/root/apps/web/pages/mobile/home/partials/hero.vue")
  },
  {
    name: "mobile-home-partials-hero___fa",
    path: "/fa/mobile/home/partials/hero",
    component: () => import("/root/apps/web/pages/mobile/home/partials/hero.vue")
  },
  {
    name: "mobile-home-partials-hero___ar",
    path: "/ar/mobile/home/partials/hero",
    component: () => import("/root/apps/web/pages/mobile/home/partials/hero.vue")
  },
  {
    name: "mobile-home-partials-projects___en",
    path: "/mobile/home/partials/projects",
    component: () => import("/root/apps/web/pages/mobile/home/partials/projects.vue")
  },
  {
    name: "mobile-home-partials-projects___tr",
    path: "/tr/mobile/home/partials/projects",
    component: () => import("/root/apps/web/pages/mobile/home/partials/projects.vue")
  },
  {
    name: "mobile-home-partials-projects___ru",
    path: "/ru/mobile/home/partials/projects",
    component: () => import("/root/apps/web/pages/mobile/home/partials/projects.vue")
  },
  {
    name: "mobile-home-partials-projects___fa",
    path: "/fa/mobile/home/partials/projects",
    component: () => import("/root/apps/web/pages/mobile/home/partials/projects.vue")
  },
  {
    name: "mobile-home-partials-projects___ar",
    path: "/ar/mobile/home/partials/projects",
    component: () => import("/root/apps/web/pages/mobile/home/partials/projects.vue")
  },
  {
    name: "mobile-more___en",
    path: "/mobile/more",
    component: () => import("/root/apps/web/pages/mobile/more/index.vue")
  },
  {
    name: "mobile-more___tr",
    path: "/tr/mobile/more",
    component: () => import("/root/apps/web/pages/mobile/more/index.vue")
  },
  {
    name: "mobile-more___ru",
    path: "/ru/mobile/more",
    component: () => import("/root/apps/web/pages/mobile/more/index.vue")
  },
  {
    name: "mobile-more___fa",
    path: "/fa/mobile/more",
    component: () => import("/root/apps/web/pages/mobile/more/index.vue")
  },
  {
    name: "mobile-more___ar",
    path: "/ar/mobile/more",
    component: () => import("/root/apps/web/pages/mobile/more/index.vue")
  },
  {
    name: "mobile-more-partials-account___en",
    path: "/mobile/more/partials/account",
    component: () => import("/root/apps/web/pages/mobile/more/partials/account.vue")
  },
  {
    name: "mobile-more-partials-account___tr",
    path: "/tr/mobile/more/partials/account",
    component: () => import("/root/apps/web/pages/mobile/more/partials/account.vue")
  },
  {
    name: "mobile-more-partials-account___ru",
    path: "/ru/mobile/more/partials/account",
    component: () => import("/root/apps/web/pages/mobile/more/partials/account.vue")
  },
  {
    name: "mobile-more-partials-account___fa",
    path: "/fa/mobile/more/partials/account",
    component: () => import("/root/apps/web/pages/mobile/more/partials/account.vue")
  },
  {
    name: "mobile-more-partials-account___ar",
    path: "/ar/mobile/more/partials/account",
    component: () => import("/root/apps/web/pages/mobile/more/partials/account.vue")
  },
  {
    name: "mobile-more-partials-upload-image___en",
    path: "/mobile/more/partials/upload-image",
    component: () => import("/root/apps/web/pages/mobile/more/partials/upload-image.vue")
  },
  {
    name: "mobile-more-partials-upload-image___tr",
    path: "/tr/mobile/more/partials/upload-image",
    component: () => import("/root/apps/web/pages/mobile/more/partials/upload-image.vue")
  },
  {
    name: "mobile-more-partials-upload-image___ru",
    path: "/ru/mobile/more/partials/upload-image",
    component: () => import("/root/apps/web/pages/mobile/more/partials/upload-image.vue")
  },
  {
    name: "mobile-more-partials-upload-image___fa",
    path: "/fa/mobile/more/partials/upload-image",
    component: () => import("/root/apps/web/pages/mobile/more/partials/upload-image.vue")
  },
  {
    name: "mobile-more-partials-upload-image___ar",
    path: "/ar/mobile/more/partials/upload-image",
    component: () => import("/root/apps/web/pages/mobile/more/partials/upload-image.vue")
  },
  {
    name: "mobile-projects___en",
    path: "/mobile/projects",
    component: () => import("/root/apps/web/pages/mobile/projects/index.vue")
  },
  {
    name: "mobile-projects___tr",
    path: "/tr/mobile/projects",
    component: () => import("/root/apps/web/pages/mobile/projects/index.vue")
  },
  {
    name: "mobile-projects___ru",
    path: "/ru/mobile/projects",
    component: () => import("/root/apps/web/pages/mobile/projects/index.vue")
  },
  {
    name: "mobile-projects___fa",
    path: "/fa/mobile/projects",
    component: () => import("/root/apps/web/pages/mobile/projects/index.vue")
  },
  {
    name: "mobile-projects___ar",
    path: "/ar/mobile/projects",
    component: () => import("/root/apps/web/pages/mobile/projects/index.vue")
  },
  {
    name: "mobile-projects-list___en",
    path: "/mobile/projects/list",
    component: () => import("/root/apps/web/pages/mobile/projects/list.vue")
  },
  {
    name: "mobile-projects-list___tr",
    path: "/tr/mobile/projects/list",
    component: () => import("/root/apps/web/pages/mobile/projects/list.vue")
  },
  {
    name: "mobile-projects-list___ru",
    path: "/ru/mobile/projects/list",
    component: () => import("/root/apps/web/pages/mobile/projects/list.vue")
  },
  {
    name: "mobile-projects-list___fa",
    path: "/fa/mobile/projects/list",
    component: () => import("/root/apps/web/pages/mobile/projects/list.vue")
  },
  {
    name: "mobile-projects-list___ar",
    path: "/ar/mobile/projects/list",
    component: () => import("/root/apps/web/pages/mobile/projects/list.vue")
  },
  {
    name: "mobile-projects-partials-contact-modal___en",
    path: "/mobile/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/contact-modal.vue")
  },
  {
    name: "mobile-projects-partials-contact-modal___tr",
    path: "/tr/mobile/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/contact-modal.vue")
  },
  {
    name: "mobile-projects-partials-contact-modal___ru",
    path: "/ru/mobile/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/contact-modal.vue")
  },
  {
    name: "mobile-projects-partials-contact-modal___fa",
    path: "/fa/mobile/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/contact-modal.vue")
  },
  {
    name: "mobile-projects-partials-contact-modal___ar",
    path: "/ar/mobile/projects/partials/contact-modal",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/contact-modal.vue")
  },
  {
    name: "mobile-projects-partials-filters___en",
    path: "/mobile/projects/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters.vue"),
    children: [
  {
    name: "mobile-projects-partials-filters-checkbox___en",
    path: "checkbox",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-projects-partials-filters-element___en",
    path: "element",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/element.vue")
  },
  {
    name: "mobile-projects-partials-filters-location___en",
    path: "location",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/location.vue")
  },
  {
    name: "mobile-projects-partials-filters-radio___en",
    path: "radio",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/radio.vue")
  },
  {
    name: "mobile-projects-partials-filters-range___en",
    path: "range",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/range.vue")
  }
]
  },
  {
    name: "mobile-projects-partials-filters___tr",
    path: "/tr/mobile/projects/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters.vue"),
    children: [
  {
    name: "mobile-projects-partials-filters-checkbox___tr",
    path: "checkbox",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-projects-partials-filters-element___tr",
    path: "element",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/element.vue")
  },
  {
    name: "mobile-projects-partials-filters-location___tr",
    path: "location",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/location.vue")
  },
  {
    name: "mobile-projects-partials-filters-radio___tr",
    path: "radio",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/radio.vue")
  },
  {
    name: "mobile-projects-partials-filters-range___tr",
    path: "range",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/range.vue")
  }
]
  },
  {
    name: "mobile-projects-partials-filters___ru",
    path: "/ru/mobile/projects/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters.vue"),
    children: [
  {
    name: "mobile-projects-partials-filters-checkbox___ru",
    path: "checkbox",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-projects-partials-filters-element___ru",
    path: "element",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/element.vue")
  },
  {
    name: "mobile-projects-partials-filters-location___ru",
    path: "location",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/location.vue")
  },
  {
    name: "mobile-projects-partials-filters-radio___ru",
    path: "radio",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/radio.vue")
  },
  {
    name: "mobile-projects-partials-filters-range___ru",
    path: "range",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/range.vue")
  }
]
  },
  {
    name: "mobile-projects-partials-filters___fa",
    path: "/fa/mobile/projects/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters.vue"),
    children: [
  {
    name: "mobile-projects-partials-filters-checkbox___fa",
    path: "checkbox",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-projects-partials-filters-element___fa",
    path: "element",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/element.vue")
  },
  {
    name: "mobile-projects-partials-filters-location___fa",
    path: "location",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/location.vue")
  },
  {
    name: "mobile-projects-partials-filters-radio___fa",
    path: "radio",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/radio.vue")
  },
  {
    name: "mobile-projects-partials-filters-range___fa",
    path: "range",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/range.vue")
  }
]
  },
  {
    name: "mobile-projects-partials-filters___ar",
    path: "/ar/mobile/projects/partials/filters",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters.vue"),
    children: [
  {
    name: "mobile-projects-partials-filters-checkbox___ar",
    path: "checkbox",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-projects-partials-filters-element___ar",
    path: "element",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/element.vue")
  },
  {
    name: "mobile-projects-partials-filters-location___ar",
    path: "location",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/location.vue")
  },
  {
    name: "mobile-projects-partials-filters-radio___ar",
    path: "radio",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/radio.vue")
  },
  {
    name: "mobile-projects-partials-filters-range___ar",
    path: "range",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/filters/range.vue")
  }
]
  },
  {
    name: "mobile-projects-partials-project-card___en",
    path: "/mobile/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/project-card.vue")
  },
  {
    name: "mobile-projects-partials-project-card___tr",
    path: "/tr/mobile/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/project-card.vue")
  },
  {
    name: "mobile-projects-partials-project-card___ru",
    path: "/ru/mobile/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/project-card.vue")
  },
  {
    name: "mobile-projects-partials-project-card___fa",
    path: "/fa/mobile/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/project-card.vue")
  },
  {
    name: "mobile-projects-partials-project-card___ar",
    path: "/ar/mobile/projects/partials/project-card",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/project-card.vue")
  },
  {
    name: "mobile-projects-partials-sort___en",
    path: "/mobile/projects/partials/sort",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/sort.vue")
  },
  {
    name: "mobile-projects-partials-sort___tr",
    path: "/tr/mobile/projects/partials/sort",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/sort.vue")
  },
  {
    name: "mobile-projects-partials-sort___ru",
    path: "/ru/mobile/projects/partials/sort",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/sort.vue")
  },
  {
    name: "mobile-projects-partials-sort___fa",
    path: "/fa/mobile/projects/partials/sort",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/sort.vue")
  },
  {
    name: "mobile-projects-partials-sort___ar",
    path: "/ar/mobile/projects/partials/sort",
    component: () => import("/root/apps/web/pages/mobile/projects/partials/sort.vue")
  },
  {
    name: "mobile-projects-show___en",
    path: "/mobile/projects/show",
    component: () => import("/root/apps/web/pages/mobile/projects/show.vue")
  },
  {
    name: "mobile-projects-show___tr",
    path: "/tr/mobile/projects/show",
    component: () => import("/root/apps/web/pages/mobile/projects/show.vue")
  },
  {
    name: "mobile-projects-show___ru",
    path: "/ru/mobile/projects/show",
    component: () => import("/root/apps/web/pages/mobile/projects/show.vue")
  },
  {
    name: "mobile-projects-show___fa",
    path: "/fa/mobile/projects/show",
    component: () => import("/root/apps/web/pages/mobile/projects/show.vue")
  },
  {
    name: "mobile-projects-show___ar",
    path: "/ar/mobile/projects/show",
    component: () => import("/root/apps/web/pages/mobile/projects/show.vue")
  },
  {
    name: "mobile-search___en",
    path: "/mobile/search",
    component: () => import("/root/apps/web/pages/mobile/search/index.vue")
  },
  {
    name: "mobile-search___tr",
    path: "/tr/mobile/search",
    component: () => import("/root/apps/web/pages/mobile/search/index.vue")
  },
  {
    name: "mobile-search___ru",
    path: "/ru/mobile/search",
    component: () => import("/root/apps/web/pages/mobile/search/index.vue")
  },
  {
    name: "mobile-search___fa",
    path: "/fa/mobile/search",
    component: () => import("/root/apps/web/pages/mobile/search/index.vue")
  },
  {
    name: "mobile-search___ar",
    path: "/ar/mobile/search",
    component: () => import("/root/apps/web/pages/mobile/search/index.vue")
  },
  {
    name: "mobile-search-partials-filters-checkbox___en",
    path: "/mobile/search/partials/filters/checkbox",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-search-partials-filters-checkbox___tr",
    path: "/tr/mobile/search/partials/filters/checkbox",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-search-partials-filters-checkbox___ru",
    path: "/ru/mobile/search/partials/filters/checkbox",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-search-partials-filters-checkbox___fa",
    path: "/fa/mobile/search/partials/filters/checkbox",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-search-partials-filters-checkbox___ar",
    path: "/ar/mobile/search/partials/filters/checkbox",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/checkbox.vue")
  },
  {
    name: "mobile-search-partials-filters-element___en",
    path: "/mobile/search/partials/filters/element",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/element.vue")
  },
  {
    name: "mobile-search-partials-filters-element___tr",
    path: "/tr/mobile/search/partials/filters/element",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/element.vue")
  },
  {
    name: "mobile-search-partials-filters-element___ru",
    path: "/ru/mobile/search/partials/filters/element",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/element.vue")
  },
  {
    name: "mobile-search-partials-filters-element___fa",
    path: "/fa/mobile/search/partials/filters/element",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/element.vue")
  },
  {
    name: "mobile-search-partials-filters-element___ar",
    path: "/ar/mobile/search/partials/filters/element",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/element.vue")
  },
  {
    name: "mobile-search-partials-filters-location___en",
    path: "/mobile/search/partials/filters/location",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/location.vue")
  },
  {
    name: "mobile-search-partials-filters-location___tr",
    path: "/tr/mobile/search/partials/filters/location",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/location.vue")
  },
  {
    name: "mobile-search-partials-filters-location___ru",
    path: "/ru/mobile/search/partials/filters/location",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/location.vue")
  },
  {
    name: "mobile-search-partials-filters-location___fa",
    path: "/fa/mobile/search/partials/filters/location",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/location.vue")
  },
  {
    name: "mobile-search-partials-filters-location___ar",
    path: "/ar/mobile/search/partials/filters/location",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/location.vue")
  },
  {
    name: "mobile-search-partials-filters-multi-taps___en",
    path: "/mobile/search/partials/filters/multi-taps",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/multi-taps.vue")
  },
  {
    name: "mobile-search-partials-filters-multi-taps___tr",
    path: "/tr/mobile/search/partials/filters/multi-taps",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/multi-taps.vue")
  },
  {
    name: "mobile-search-partials-filters-multi-taps___ru",
    path: "/ru/mobile/search/partials/filters/multi-taps",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/multi-taps.vue")
  },
  {
    name: "mobile-search-partials-filters-multi-taps___fa",
    path: "/fa/mobile/search/partials/filters/multi-taps",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/multi-taps.vue")
  },
  {
    name: "mobile-search-partials-filters-multi-taps___ar",
    path: "/ar/mobile/search/partials/filters/multi-taps",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/multi-taps.vue")
  },
  {
    name: "mobile-search-partials-filters-radio___en",
    path: "/mobile/search/partials/filters/radio",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/radio.vue")
  },
  {
    name: "mobile-search-partials-filters-radio___tr",
    path: "/tr/mobile/search/partials/filters/radio",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/radio.vue")
  },
  {
    name: "mobile-search-partials-filters-radio___ru",
    path: "/ru/mobile/search/partials/filters/radio",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/radio.vue")
  },
  {
    name: "mobile-search-partials-filters-radio___fa",
    path: "/fa/mobile/search/partials/filters/radio",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/radio.vue")
  },
  {
    name: "mobile-search-partials-filters-radio___ar",
    path: "/ar/mobile/search/partials/filters/radio",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/radio.vue")
  },
  {
    name: "mobile-search-partials-filters-range___en",
    path: "/mobile/search/partials/filters/range",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/range.vue")
  },
  {
    name: "mobile-search-partials-filters-range___tr",
    path: "/tr/mobile/search/partials/filters/range",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/range.vue")
  },
  {
    name: "mobile-search-partials-filters-range___ru",
    path: "/ru/mobile/search/partials/filters/range",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/range.vue")
  },
  {
    name: "mobile-search-partials-filters-range___fa",
    path: "/fa/mobile/search/partials/filters/range",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/range.vue")
  },
  {
    name: "mobile-search-partials-filters-range___ar",
    path: "/ar/mobile/search/partials/filters/range",
    component: () => import("/root/apps/web/pages/mobile/search/partials/filters/range.vue")
  },
  {
    name: "mobile-search-partials-search-by-listing-id___en",
    path: "/mobile/search/partials/search-by-listing-id",
    component: () => import("/root/apps/web/pages/mobile/search/partials/search-by-listing-id.vue")
  },
  {
    name: "mobile-search-partials-search-by-listing-id___tr",
    path: "/tr/mobile/search/partials/search-by-listing-id",
    component: () => import("/root/apps/web/pages/mobile/search/partials/search-by-listing-id.vue")
  },
  {
    name: "mobile-search-partials-search-by-listing-id___ru",
    path: "/ru/mobile/search/partials/search-by-listing-id",
    component: () => import("/root/apps/web/pages/mobile/search/partials/search-by-listing-id.vue")
  },
  {
    name: "mobile-search-partials-search-by-listing-id___fa",
    path: "/fa/mobile/search/partials/search-by-listing-id",
    component: () => import("/root/apps/web/pages/mobile/search/partials/search-by-listing-id.vue")
  },
  {
    name: "mobile-search-partials-search-by-listing-id___ar",
    path: "/ar/mobile/search/partials/search-by-listing-id",
    component: () => import("/root/apps/web/pages/mobile/search/partials/search-by-listing-id.vue")
  },
  {
    name: "mobile-sitemaps___en",
    path: "/mobile/sitemaps",
    component: () => import("/root/apps/web/pages/mobile/sitemaps/index.vue")
  },
  {
    name: "mobile-sitemaps___tr",
    path: "/tr/mobile/sitemaps",
    component: () => import("/root/apps/web/pages/mobile/sitemaps/index.vue")
  },
  {
    name: "mobile-sitemaps___ru",
    path: "/ru/mobile/sitemaps",
    component: () => import("/root/apps/web/pages/mobile/sitemaps/index.vue")
  },
  {
    name: "mobile-sitemaps___fa",
    path: "/fa/mobile/sitemaps",
    component: () => import("/root/apps/web/pages/mobile/sitemaps/index.vue")
  },
  {
    name: "mobile-sitemaps___ar",
    path: "/ar/mobile/sitemaps",
    component: () => import("/root/apps/web/pages/mobile/sitemaps/index.vue")
  },
  {
    name: "mobile-slug___en",
    path: "/mobile/slug",
    component: () => import("/root/apps/web/pages/mobile/slug/index.vue")
  },
  {
    name: "mobile-slug___tr",
    path: "/tr/mobile/slug",
    component: () => import("/root/apps/web/pages/mobile/slug/index.vue")
  },
  {
    name: "mobile-slug___ru",
    path: "/ru/mobile/slug",
    component: () => import("/root/apps/web/pages/mobile/slug/index.vue")
  },
  {
    name: "mobile-slug___fa",
    path: "/fa/mobile/slug",
    component: () => import("/root/apps/web/pages/mobile/slug/index.vue")
  },
  {
    name: "mobile-slug___ar",
    path: "/ar/mobile/slug",
    component: () => import("/root/apps/web/pages/mobile/slug/index.vue")
  },
  {
    name: "mobile-teammates___en",
    path: "/mobile/teammates",
    component: () => import("/root/apps/web/pages/mobile/teammates/index.vue")
  },
  {
    name: "mobile-teammates___tr",
    path: "/tr/mobile/teammates",
    component: () => import("/root/apps/web/pages/mobile/teammates/index.vue")
  },
  {
    name: "mobile-teammates___ru",
    path: "/ru/mobile/teammates",
    component: () => import("/root/apps/web/pages/mobile/teammates/index.vue")
  },
  {
    name: "mobile-teammates___fa",
    path: "/fa/mobile/teammates",
    component: () => import("/root/apps/web/pages/mobile/teammates/index.vue")
  },
  {
    name: "mobile-teammates___ar",
    path: "/ar/mobile/teammates",
    component: () => import("/root/apps/web/pages/mobile/teammates/index.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-card___en",
    path: "/mobile/teammates/partials/teammate-card",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-card.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-card___tr",
    path: "/tr/mobile/teammates/partials/teammate-card",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-card.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-card___ru",
    path: "/ru/mobile/teammates/partials/teammate-card",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-card.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-card___fa",
    path: "/fa/mobile/teammates/partials/teammate-card",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-card.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-card___ar",
    path: "/ar/mobile/teammates/partials/teammate-card",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-card.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-filter___en",
    path: "/mobile/teammates/partials/teammate-filter",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-filter.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-filter___tr",
    path: "/tr/mobile/teammates/partials/teammate-filter",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-filter.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-filter___ru",
    path: "/ru/mobile/teammates/partials/teammate-filter",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-filter.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-filter___fa",
    path: "/fa/mobile/teammates/partials/teammate-filter",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-filter.vue")
  },
  {
    name: "mobile-teammates-partials-teammate-filter___ar",
    path: "/ar/mobile/teammates/partials/teammate-filter",
    component: () => import("/root/apps/web/pages/mobile/teammates/partials/teammate-filter.vue")
  },
  {
    name: "mobile-teammates-show___en",
    path: "/mobile/teammates/show",
    component: () => import("/root/apps/web/pages/mobile/teammates/show.vue")
  },
  {
    name: "mobile-teammates-show___tr",
    path: "/tr/mobile/teammates/show",
    component: () => import("/root/apps/web/pages/mobile/teammates/show.vue")
  },
  {
    name: "mobile-teammates-show___ru",
    path: "/ru/mobile/teammates/show",
    component: () => import("/root/apps/web/pages/mobile/teammates/show.vue")
  },
  {
    name: "mobile-teammates-show___fa",
    path: "/fa/mobile/teammates/show",
    component: () => import("/root/apps/web/pages/mobile/teammates/show.vue")
  },
  {
    name: "mobile-teammates-show___ar",
    path: "/ar/mobile/teammates/show",
    component: () => import("/root/apps/web/pages/mobile/teammates/show.vue")
  }
]