import { defineStore } from "pinia";
import { useSsrFetch } from "../composables/useFetch";
export const useErrorsStore = defineStore({
	id: 'errors',
	state: () => ({
	}),
	getters: {
	},
	actions: {
		async fetchCountries() {
			return await useSsrFetch('errors/countries') || [];
		},
	}
});
