<script lang="ts">
import { useErrorsStore } from "~/stores/errors.store";

export default {
  props: {
    error: { type: Object },
  },

  async setup(props) {
    const { isDesktop } = useDevice();

    const handleError = () => clearError({ redirect: '/' });
    const countries = ref([])
    const errorsStore = useErrorsStore();
    countries.value = await errorsStore.fetchCountries();

    const appId = useRuntimeConfig().public.appId;
    return { countries, handleError, isDesktop, appId, }
  }
}
</script>

<template>

  <MobileListingNotExist v-if="error?.data && JSON.parse(error?.data) && JSON.parse(error?.data)['showSearch']"></MobileListingNotExist>
  <div class="bg-indigo-900 h-screen" v-else>
    <div class=" w-full py-4">
      <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class=" flex justify-center items-center">
          <div class="flex pt-10">
            <NuxtLink :to="localePath({ name: 'home' })" class="flex justify-center">
              <img class="sm:h-3" :src="`/images/${appId}/logo.webp`" alt="Vartur"
                :class="{ [isDesktop ? 'w-auto lg:h-10' : 'w-1/2 lg:h-5']: true }" />
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-10 text-center">
      <h2 class="error mb-10" :class="{ [isDesktop? 'text-[250px] -tracking-wide': 'text-9xl tracking-normal']: true }">
        {{ error.statusCode }}</h2>
      <button @click="handleError"
        class="mb-16 inline-flex items-center rounded-md bg-white px-4 py-2 text-sm text-indigo-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all">
        <i class="fa-solid fa-home me-3"></i>
        {{ $t('client.back_to_home') }}
      </button>
      <div v-for="(country, i) in countries" :key="i" class="grid text-white text-center text-sm mb-4"
      :class="{ [isDesktop ? 'grid-cols-3' : 'grid-cols-1 gap-4']: true }"
      >
        <div class="grid grid-cols-1 w-100 mx-auto hover:text-gray-200">
          <NuxtLink :to="localePath({ name: 'slug', params: { slug: country.main_category.url.split('/') } })">{{
            country.main_category_title }}</NuxtLink>
        </div>

        <div v-for="(category, i) in country.categories" :key="i">
          <ul>
            <li>
              <NuxtLink :to="localePath({ name: 'slug', params: { slug: category.url.split('/') } })">{{ category.title
                }}</NuxtLink>
            </li>
            <li v-for="(popular_link, idx) in category.popular_links">
              <NuxtLink :to="localePath({ name: 'slug', params: { slug: popular_link.url.split('/') } })">{{
                popular_link.title }}</NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.error {
  font-weight: bolder;
  color: #ffffff;
  text-shadow:
    1px 1px 1px #292828,
    2px 2px 1px #292828,
    3px 3px 1px #292828,
    4px 4px 1px #292828,
    5px 5px 1px #292828,
    6px 6px 1px #292828,
    7px 7px 1px #292828,
    8px 8px 1px #292828,
    25px 25px 8px rgba(0, 0, 0, 0.1);
  animation: grow 2.5s infinite ease-in-out alternate;
  transition: transform .9s ease-in-out;
  opacity: 0.5;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@keyframes grow {
  to {
    transform: translateY(20px);
    opacity: 1;
  }
}
</style>
