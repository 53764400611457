import { useRuntimeConfig, useCookie } from 'nuxt/app';
import moment from 'moment';

export default {

	getImageTypeFromBase64(base64String) {
		const matches = base64String.match(/^data:image\/([a-zA-Z0-9]+);base64,/);
		if (matches) {
			return matches[1]; // Return the image type
		}
		return null; // Invalid base64 string or no image type found
	},

	resizeImage(base64Image) {
		let newHeight = 3200;
		let newWidth = 3200;
		const ext = this.getImageTypeFromBase64(base64Image);

		return new Promise((resolve, reject) => {
			// Create an Image object
			var img = new Image();

			// Set the source of the Image object as the base64 image
			img.src = base64Image;

			// When the image has loaded
			img.onload = function () {
				// Calculate the aspect ratio of the image
				var aspectRatio = img.width / img.height;

				// Calculate the new dimensions while maintaining the aspect ratio
				if (img.width > img.height) {
					newHeight = Math.round(newWidth / aspectRatio);
				} else {
					newWidth = Math.round(newHeight * aspectRatio);
				}

				// Create a canvas element
				let canvas = document.createElement('canvas');
				canvas.width = newWidth;
				canvas.height = newHeight;

				// Draw the image onto the canvas
				var ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0, newWidth, newHeight);

				// Get the resized image as a new base64 string
				const resizedBase64 = canvas.toDataURL(`image/${ext}`);

				const finalResizedBase64 = resizedBase64.length < base64Image.length ? resizedBase64 : base64Image;

				// Use the resizedBase64 string as needed (e.g., display or upload)
				resolve(finalResizedBase64);
			};
		});
	},

	formatPrice(price) {
		if (parseFloat(price || "0") === 0) {
			return "0";
		}
		price = price.toString().replaceAll('.', '');
		var parts = price.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		return parts.join(".");
	},

	formatPriceUnits(price) {
		const priceStr = String(price);
		const num = Number(priceStr.replace(/\./g, ''));

		if (num >= 1e15) {
			return (num / 1e15 % 1 === 0 ? (num / 1e15).toFixed(0) : (num / 1e15).toFixed(1)) + 'T';
		} else if (num >= 1e12) {
			return (num / 1e12 % 1 === 0 ? (num / 1e12).toFixed(0) : (num / 1e12).toFixed(1)) + 'T';
		} else if (num >= 1e9) {
			return (num / 1e9 % 1 === 0 ? (num / 1e9).toFixed(0) : (num / 1e9).toFixed(1)) + 'B';
		} else if (num >= 1e6) {
			return (num / 1e6 % 1 === 0 ? (num / 1e6).toFixed(0) : (num / 1e6).toFixed(1)) + 'M';
		} else if (num >= 1e3) {
			return (num / 1e3 % 1 === 0 ? (num / 1e3).toFixed(0) : (num / 1e3).toFixed(1)) + 'K';
		} else {
			return num.toString(); // return the number as is for values less than 1,000
		}
	},

	isSSR() {
		return typeof window === 'undefined';
	},

	timeAgo: (date) => {
		let diff = moment().diff(moment(date), 'days');
		if (diff === 0) {
			return 'Today';
		} else if (diff === 1) {
			return 'Yesterday';
		} else if (diff > 1) {
			return diff + ' days ago';
		} else {
			return 'Invalid Date';
		}
	},

	set_cookie(name, value, options = {}) {
		options = {
			path: '/',
			...options
		};
	
		if (!options.expires) {
			const date = new Date();
			date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
			options.expires = date.toUTCString();
		} else if (options.expires instanceof Date) {
			options.expires = options.expires.toUTCString();
		}
	
		let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
	
		for (let optionKey in options) {
			updatedCookie += "; " + optionKey;
			let optionValue = options[optionKey];
			if (optionValue !== true) {
				updatedCookie += "=" + optionValue;
			}
		}
	
		document.cookie = updatedCookie;
	},

	delete_cookie(name, domain = "/") {
		document.cookie = `${name}=; path=/; domain=${domain}; expires=${new Date(0).toUTCString()}`;
	},

	getMainUrl(url) {
		let host = url.replace(/(^\w+:|^)\/\//, '').split('/')[0];
		const port = host.split(':')[1];
		if (port != undefined) {
			host = host.split(':')[0];
		}
		return host;
	},
	currencyFormat(num) {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
	},
	getLocale() {
		if (typeof window !== 'undefined' && window.location) {
			const url = window.location.href;
			const matches = url.match(/\/([a-z]{2})\//i);
			if (matches && matches.length > 1) {
				return matches[1];
			}
		}
		return 'en';
	},

	appendToUrl(url, stringToAdd) {
		if (url.endsWith('/')) {
			return url + stringToAdd;
		}
		return url + '/' + stringToAdd;
	},

	localStorage: {
		getData(item) {
			if (process.client) {
				return localStorage.getItem(item);
			} else {
				return undefined;
			}
		},
		setData(item, value) {
			if (process.client) {
				localStorage.setItem(item, value);
				return true;
			} else {
				return false;
			}
		},
	},

	cookies: {
		currency() {
			return useCookie(
				'currency',
				{
					default: () => ({"_id":2,"code":"USD","symbol":"$","rate":"1.000","name":"American Dollar"}),
					watch: true,
					sameSite: 'strict'
				}
			);
		},
	}
}
