import { defineStore } from "pinia";
import axios from 'axios'
import { useRuntimeConfig } from "nuxt/app";

export const useRolesStore = defineStore("roles", {
	state: () => ({
        roles: [] as any[],
	}),
    getters: {
        rolesGetter: (state) => () => state.roles,
    },
	actions: {
        getVcrmAccountToken() {
			if (!document) {
				return false;
			}
			const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
			if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
				return vue_cookie.split("=")[1];
			}
		},

        async fetchRoles() {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
            axios.defaults.headers['application-platform'] = 'web';

            await axios.get(`${accountApiUrl}/roles`)
                .then((res) => {
                    this.roles = res.data.user_roles
                    return res.data.user_roles
                })
                .catch(err => {
                    this.roles = []
                    return []
                })
        },
    }
});