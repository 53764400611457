<script setup>
const router = useRouter();

const goBack = () => {
  router.back();
}
</script>

<template>
  <button @click="goBack">
    <i class="fa-solid fa-chevron-left fa-xl ms-2"></i>
  </button>
</template>