import { defineStore } from "pinia";
import { useSsrFetch } from "../composables/useFetch";
import type { Currency, HasOfficeCity, HrefLanguage, Language, Teammate } from "../types/home-components.type";
import helper from '../resources/js/libraries/helper';

export const useLayoutStore = defineStore({
	id: "layout",
	state: () => ({
		languages: [] as Language[],
		currencies: [] as any[],
		language_code: "",
		cities: [],
		hasOfficeCities: [] as HasOfficeCity[],
		countries: [],
		menus_in_headers: {} as any,
		menus_in_footer: {} as any,
		helpful_links: {} as any,
		appLocale: "",
		href_langs: [] as HrefLanguage[],
		appCurrencySymbol: "",
		teammate: [] as Teammate[]
	}),
	getters: {
		base_lang_url: (state) =>
			state.appLocale && state.appLocale != "en" ? `/${state.appLocale}` : "",
		appCurrencySymbolGetter: (state) => state.appCurrencySymbol,
		languagesGetter: (state) => state.languages,
		currenciesGetters: (state) => () => state.currencies,
	},
	actions: {
		async fetchLanguages() {
			const res = (await useSsrFetch("data/languages")) as Language[];
			this.languages = (res || []).map((language) => {
				return {
					...language,
					full_url: `/${language.code === "en" ? "" : language.code + "/"}`,
				};
			});
			return this.languages;
		},
		async fetchCurrencies() {
			const res = await useSsrFetch("data/currencies");
			this.currencies = res
			return res || [];
		},
		async fetchMenusInHeader(module: string, country_id: number) {
			const queryParams = new URLSearchParams({ module });
			
			if (country_id) {
				queryParams.append('country_id', String(country_id));
			}

			const menu_key = `${module}_${country_id || 0}`;
			if (this.menus_in_headers[menu_key]) {
				return this.menus_in_headers[menu_key];
			}

			this.menus_in_headers[menu_key] = await useSsrFetch(`data/menus/header?${queryParams}`, "GET");
			return this.menus_in_headers[menu_key];
		},

		async fetchMenusInFooter(module: string, country_id?: number) {
			const queryParams = new URLSearchParams({ module });

			if (country_id) {
				queryParams.append('country_id', String(country_id));
			}

			const menu_key = `${module}_${country_id || 0}`;
			if (this.menus_in_footer[menu_key]) {
				return this.menus_in_footer[menu_key];
			}

			this.menus_in_footer[menu_key] = await useSsrFetch(`data/menus/footer?${queryParams}`);
			return this.menus_in_footer[menu_key];
		},

		async fetchHelpfulLinks(module = 'website') {
			const queryParams = new URLSearchParams({ module });

			if(this.helpful_links[module]) {
				return this.helpful_links[module];
			}

			this.helpful_links[module] = await useSsrFetch(`data/menus/helpful-links?${queryParams}`);
			return this.helpful_links[module];
		},
		async fetchHasOfficeCities(): Promise<HasOfficeCity[]> {
			const cities = await useSsrFetch('data/cities/has-office') as HasOfficeCity[];
			this.hasOfficeCities = cities;
			return cities || [];
		},
		async fetchTeammate(): Promise<Teammate[]> {
			const teammate = (await useSsrFetch("pages/teammate")) as Teammate[];
			this.teammate = teammate;
			return teammate || [];
		}
	},
});
