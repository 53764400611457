import { defineAsyncComponent } from 'vue'
export default {
  default: defineAsyncComponent(() => import("/root/apps/web/layouts/default.vue")),
  "desktop-blog-header-menu-header": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/blog/header/menu-header.vue")),
  "desktop-blog": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/blog/index.vue")),
  "desktop-default": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/desktop-default.vue")),
  "desktop-guides-header-menu-header": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/guides/header/menu-header.vue")),
  "desktop-guides": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/guides/index.vue")),
  "desktop-main-footer-contact-us": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/main/footer/contact-us.vue")),
  "desktop-main-footer-countries-menus": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/main/footer/countries-menus.vue")),
  "desktop-main-footer-helpful-links": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/main/footer/helpful-links.vue")),
  "desktop-main-footer-social-media": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/main/footer/social-media.vue")),
  "desktop-main-header-account": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/main/header/account.vue")),
  "desktop-main-header-currencies": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/main/header/currencies.vue")),
  "desktop-main-header-languages": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/main/header/languages.vue")),
  "desktop-main-header-menu-header": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/main/header/menu-header.vue")),
  "desktop-projects-footer-countries-menus": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/projects/footer/countries-menus.vue")),
  "desktop-projects-footer-helpful-links": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/projects/footer/helpful-links.vue")),
  "desktop-projects": defineAsyncComponent(() => import("/root/apps/web/layouts/desktop/projects/index.vue")),
  "mobile-base": defineAsyncComponent(() => import("/root/apps/web/layouts/mobile-base/mobile-base.vue")),
  "mobile-base-partials-download-app": defineAsyncComponent(() => import("/root/apps/web/layouts/mobile-base/partials/download-app.vue")),
  "tablet-base": defineAsyncComponent(() => import("/root/apps/web/layouts/tablet-base/tablet-base.vue"))
}