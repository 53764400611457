import {useUserStore} from '~/stores/user.store.ts'

export default defineNuxtRouteMiddleware(async (to, from) => {
    const appliedRoutesName = ['listing.add']

    const userStore = useUserStore();
    
    const name = to.name.split("___")[0]

    if (appliedRoutesName.includes(name)) {

    }
})